img.notification_userImg {
    width: 35px;
    height: 35px;
    border-radius: 50px;
    object-fit: contain;
}
b.blur_date {
    color: #ccc;
}

p.noti_list {
    font-weight: 500;
    font-size: 16px;
}
a.close_noti span {
    font-size: 25px;
    color: #46a6de;
}