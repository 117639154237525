.landingPage2Child {
  width: 100%;
}
.theMostTalented {
  margin: 0;
}
.theMostTalentedContainer {
  font-size: 35px;
  line-height: 128%;
  color: var(--color-gray-100);
  display: inline-block;
  // width: 578px;
}

.scheduleADemo {
  position: relative;
  font-weight: 600;
  font-size: 16px;
}
.postAProjectWrapper,
.scheduleADemoWrapper {
  border-radius: var(--br-3xs);
  background-color: var(--color-deepskyblue-100);
  flex-direction: row;
  padding: var(--padding-xl) var(--padding-5xl);
  align-items: flex-start;
  justify-content: flex-start;
  text-align: center;
  width: 200px;
  color: #fff;
}
.postAProjectWrapper {
  background-color: var(--color-mediumseagreen);
  text-align: center;
}
.image4Icon {
  width: 25px;
  height: 25px;
  object-fit: cover;
}
.theLatestProjects {
  position: relative;
  font-size: 26px;
}
.works {
  color: var(--color-deepskyblue-100);
}
.recentlyPostedWorksContainer {
  position: relative;
  font-size: var(--font-size-37xl);
  font-weight: 500;
  color: var(--color-gray-100);
}
.theLatestProjectsWorkParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
 
}
.frameChild {
  position: relative;
  width: 110px;
  height: 74px;
}
.frameParent {
  display: flex;
  /* flex-direction: row; */
  align-items: center;
  justify-content: space-between;
  /* gap: 400px; */
  width: 100%;
}
.materialUiIcon {
  position: relative;
  width: 80px;
  height: 80px;
  object-fit: cover;
}
.logoDesign {
  position: relative;
  font-weight: 500;
  font-size: 22px;
  margin-bottom: 6px;
  // color: #252525;
  color: #000;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.graphicDesign{
  position: relative;
  display: flex;
  justify-content: center;
}
.graphicDesign:before {
  content: "";
  background-image: linear-gradient(to right, #ececec00, #00000080);
  position: absolute;
  left: 0px;
  width: 100%;
  height: 240px;
  border-radius: 18px;
}
.needAProfessional {
  position: relative;
  // font-size: var(--font-size-5xl);
  font-size: 17px;
  color: var(--color-darkgray-100); 
  display: inline-block;
  // max-width: 331px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.needAProfessional p{
  font-size: 15px;
}
.skillsLabel{
  font-weight: 600;
  color: #000;
}
.skillslData{
  font-weight: 500 !important;
  width:100%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  height: 20px;
}
.ratethisSkills{
  font-size: 12px !important;
}
.logoDesignParent { 
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--gap-8xs);
}
.span {
  font-size: var(--font-size-5xl);
}
.span1 {
  font-size: var(--font-size-base);
  margin-left: 6px;
  font-weight: 600;
}
.highestBid500Parent div {
  // color: #252525;
  color: #000;
}
.applyNow,
.highestBid500Container {
  font-weight: 500;
}
.highestBid500Container {
  left: 0;
  display: inline-block;
  // width: 119px;
  opacity: 0.7;
}
.applyNow {
  left: 206px;
  font-size: var(--font-size-5xl);
  text-decoration: underline;
  color: var(--color-deepskyblue-100);
  text-align: left;
}
.highestBid500Parent {
  display: flex;
  justify-content: space-between;
  width: 100%;
  text-align: center;
  font-size: var(--font-size-xs);
}
.materialUiParent {
  // border-radius: var(--br-11xl);
  background-color: var(--white);
  // box-shadow: 0 1px 6px rgba(0, 0, 0, 0.1); 
  flex-direction: column;
  padding: var(--padding-xl);
  padding-bottom: 5px !important;
  align-items: center;
  justify-content: center;
  gap: var(--gap-11xl);
  width: 100%;
  background: #fff;
  padding: 23px;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  // margin: 5px;
}
.materialUiParent:hover {
background: #008aff1f !important;
    color: #fff !important;
}
// .materialUiParent:hover .logoDesign {
//   color: #fff;
// }
// .materialUiParent:hover .needAProfessional{
//   color: #fff;
// }
// .materialUiParent:hover .highestBid500Container{
//   color: #fff;
// }
// .materialUiParent:hover .apply_Now{
//   color: #fff !important;
// }
.rectangleParent.shadow {
  border-radius: 10px;
}
// .graphicDesign {
//   position: absolute;
//   font-weight: 500;
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%, -50%);
//   // width: 100%;
//   width: 91%;
//   text-align: center;
//   font-size: 20px;
// }
.col-md-3.position-relative {
  margin-bottom: 25px;
}
.weNeedA {
  font-size: var(--font-size-5xl);
  color: var(--color-darkgray-100);
  text-align: center;
  display: inline-block;
  max-width: 331px;
}
.graphicDesignParent {
  position: relative;
  width: 331px;
  height: 161px;
}
.highestBid500Container1 {
  font-weight: 500;
  opacity: 0.7;
}
.applyNow1 {
  font-size: var(--font-size-5xl);
  text-decoration: underline;
  font-weight: 500;
  color: var(--color-deepskyblue-100);
  text-align: left;
}
.highestBid500Group,
.needASeo1 {
  position: relative;
  text-align: center;
}
.highestBid500Group {
  width: 320px;
  height: 36px;
  font-size: var(--font-size-xs);
}
.needASeo1 {
  font-size: var(--font-size-5xl);
  color: var(--color-darkgray-100);
  display: inline-block;
  max-width: 321px;
}
.highestBid300Container {
  position: relative;
  font-weight: 500;
  opacity: 0.7;
}
.applyNow2 {
  position: relative;
  font-size: var(--font-size-5xl);
  text-decoration: underline;
  font-weight: 500;
  color: var(--color-deepskyblue-100);
  text-align: left;
}
.highestBid300Parent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 68px;
  text-align: center;
  font-size: var(--font-size-xs);
}
.frameGroup,
.recentlyPosted {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.carousel-item div {
  flex-wrap: wrap;
}
.frameGroup {
  flex-direction: row;
  gap: 76px;
  font-size: var(--font-size-13xl);
  color: var(--color-gray-100);
}
.recentlyPosted {
  flex-direction: column;
  gap: var(--gap-56xl);
  font-size: var(--font-size-17xl);
  color: var(--color-darkgray-100);
}
.span4 {
  color: var(--color-cornflowerblue);
}
.chooseDifferentCategoryContainer {
  font-size: var(--font-size-37xl);
  font-weight: 500;
  color: var(--color-gray-100);
  // text-align: center;
  margin-top: 2rem;
}
.unsplashgxgtqg5ul2gIcon,
.unsplashv9vii5gv8lwIcon {
  border-radius: var(--br-xl);
  width:100%;
  height: 241px;
  object-fit: cover;
}
.unsplashgxgtqg5ul2gIcon {
}
.unsplash15widdvl5duIcon,
.unsplashs9cc2skysjmIcon,
.unsplashuhtdg9eplqiIcon {
  border-radius: var(--br-xl);
  width: 100%;
  height: 242px;
  object-fit: cover;
}
.unsplashs9cc2skysjmIcon,
.unsplashuhtdg9eplqiIcon {
}
.unsplashuhtdg9eplqiIcon {
  height: 241px;
}
.unsplashqnwpjzewewaIcon {
  object-fit: cover;
}
.categoriesChild,
.unsplashjpd5a9MsGIcon,
.unsplashqnwpjzewewaIcon,
.unsplashvw2ou66mwbcIcon {
  border-radius: var(--br-xl);
  width: 100%;
  height: 241px;
}
.unsplashjpd5a9MsGIcon {
  object-fit: cover;
}

.unsplashvw2ou66mwbcIcon {
  object-fit: cover;
}
.categoriesChild {
  background-color: rgba(0, 0, 0, 0.4);
}
.categoriesChild1,
.categoriesInner,
.categoriesItem,
.rectangleDiv {
  border-radius: var(--br-xl);
  background-color: var(--color-gray-400);
  width: 290px;
  height: 241px;
}
.categoriesChild1,
.categoriesInner,
.rectangleDiv {
  background-color: var(--color-gray-300);
}
.categoriesChild1,
.rectangleDiv {
  background-color: var(--color-gray-400);
}

.categoriesChild2,
.categoriesChild3 {
  background-color: var(--color-gray-300);
}
.categoriesChild2 {
  border-radius: var(--br-xl);
  width: 290px;
  height: 241px;
}

.categoriesChild3,
.categoriesChild4,
.categoriesChild5 {
  border-radius: var(--br-xl);
  width: 290px;
  height: 241px;
}
.categoriesChild4 {
  background-color: var(--color-gray-400);
}
.categoriesChild5 {
  background-color: rgba(0, 0, 0, 0.2);
}
.graphicDesign1 {
  font-weight: 600;
}
//   .cartoonAnimation,
//   .flyersVouchers,
//   .illustration,
//   .logoDesign1 {

//     font-weight: 600;
//   }
//   .flyersVouchers,
//   .illustration,
//   .logoDesign1 {
//     left: 1017px;
//   }
//   .illustration,
//   .logoDesign1 {
//     left: 729px;
//   }
//   .logoDesign1 {
//     top: 554px;
//     left: 73px;
//   }
.articleWriting,
.socialGraphics,
.videoEditing {
  font-weight: 600;
}
//   .articleWriting,
//   .videoEditing {
//     left: 708px;
//   }
//   .videoEditing {
//     left: 1042px;
//   }
.categories {
  width: 100%;
}
.Assistants {
  font-size: 18px;
  line-height: 30px;
}
.moreCategoriesWrapper {
  width: fit-content;
  display: block;
  margin: auto;
  margin-top: 2rem;
  margin-bottom: 9rem;
  border-radius: var(--br-3xs);
  // background-color: var(--color-deepskyblue-100);
  display: flex;
  flex-direction: row;
  padding: var(--padding-xl);
  align-items: center;
  justify-content: center;
}
.logosWebsitesBookCoversParent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2px;
}
.groupChild,
.groupItem {
  // border-radius: var(--br-3xs);
  border-radius: 10px 10px 0px 0px;
  width: 100%;
}
.groupChild {
  background-color: var(--white);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  height: 472px;
}
.groupItem {
  height: 280px;
  object-fit: cover;
}
.uiuxDesigner {
  position: relative;
  font-size: var(--font-size-5xl);
  color: var(--color-darkgray-100);
}
.biarrowRightIcon {
  position: relative;
  width: 30px;
  height: 30px;
  overflow: hidden;
  flex-shrink: 0;
}
.frameContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: 13px;
  justify-content: space-between;
  // gap: 200px;
  height: 70px;

}
.rectangleParent {
  position: relative;
  // max-width: 390px;
  width: 100% !important;
  max-height: 472px;

}
.frameDiv {
  align-items: center;
  justify-content: space-around;
  gap: 95px;
}
.frameDiv,
.frameParent1,
.groupParent {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.groupParent .slick-track{
  // margin: 6px;
}
.frameParent1 {
  align-items: center;
  justify-content: space-around;
  gap: 107px;
}
.groupParent {
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  // gap: 32px;
  font-size: var(--font-size-11xl);
  color: var(--color-gray-100);
}
.groupChild2,
.groupChild3,
.groupChild4 {
  border-radius: var(--br-3xs);
  background-color: var(--color-deepskyblue-100);
  width: 82px;
  height: 12px;
}
.groupChild3,
.groupChild4 {
  background-color: var(--color-darkgray-100);
  width: 47px;
}
.groupChild4 {
  left: 163px;
}
.groupDiv {
  position: relative;
  width: 210px;
  height: 12px;
}
.portfolios {
  display: flex;
  flex-direction: column;
  // align-items: center;
  justify-content: center;
  gap: var(--gap-56xl);
  font-size: var(--font-size-17xl);
  color: var(--color-darkgray-100);
}
.newsletterSubscriptionChild {
  background-color: #f2fafa;
  width: 100%;
  height: 561px;
}
.newsletterSubscriptionParent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--gap-8xs);
}
.enterYourEmailAddressWrapper,
.subscribeWrapper {
  border-radius: var(--br-3xs);
  background-color: var(--color-deepskyblue-100);
  display: flex;
  flex-direction: row;
  padding: var(--padding-xl) 89px;
  align-items: flex-start;
  justify-content: flex-start;
}
.enterYourEmailAddressWrapper {
  background-color: var(--white);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: var(--padding-xl) 182px;
  color: var(--color-darkgray-100);
}
.frameParent2 {
  width: 662px;
  height: 194px;
  font-size: var(--font-size-5xl);
  color: var(--white);
}
.newsletterSubscription {
  width: 100%;
  height: 561px;
  overflow: hidden;
  font-size: var(--font-size-37xl);
  color: var(--color-gray-100);
}
.pngegg97Icon {
  width: 616px;
  height: 587px;
  object-fit: cover;
}
.span5 {
  font-weight: 600;
}
.virtualAssistants1 {
  margin: 0;
  font-size: var(--font-size-5xl);
  color: var(--color-darkgray-100);
}
.projectsWrapper,
.virtualAssistantsWrapper {
  border-radius: var(--br-xl);
  background-color: var(--white);
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: row;
  padding: 23px 16px;
  align-items: center;
  justify-content: center;
}
.projectsWrapper {
  padding: 27px 13px;
}
.pngegg97Parent {
  position: relative;
  width: 616px;
  height: 587px;
}
.findTheBest {
  position: relative;
  line-height: 123.5%;
  font-weight: 600;
  display: inline-block;
  // max-width: 572px;
  color: #20a2dc;
  font-size: 45px;
  text-align: right;
}
.line_bottom:after {
  position: absolute;
  bottom: 0;
  content: "";
  background: #20a2dc;
  height: 12px;
  width: 100%;
}
.line_bottom {
  position: relative;
}
.loremIpsumDolorContainer {
  position: relative;
  font-size: var(--font-size-5xl);
  color: var(--color-darkgray-100);
  display: inline-block;
  // max-width: 458px;
  // height: 314px;
  flex-shrink: 0;
  text-align: right;
}
.findTheBestVirtualAssistanParent {
  display: flex;
  flex-direction: column;
  // align-items: flex-end;
  justify-content: center;
  gap: 20px;
  text-align: right;
  font-size: var(--font-size-37xl);
  text-align: left;
  height: 100%;
}
.groupContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 85px;
  text-align: center;
  font-size: var(--font-size-17xl);
  color: var(--color-deepskyblue-100);
}
.landingPage2Item {
  border-radius: 50%;
  background-color: rgba(32, 162, 220, 0.1);
  width: 532px;
  height: 532px;
}
.smilingExecutivesBeforeMeetIcon {
  width: 719px;
  height: 824px;
  object-fit: cover;
}
.frameItem {
  position: relative;
  width: 45px;
  height: 45px;
  // object-fit: cover;
  object-fit: contain;
}
.bgimgg{
  background: #3aa0dc14;
  padding:  20px;
  border-radius: 100%;
}
.firstYouHave {
  position: relative;
  font-size: var(--font-size-5xl);
  color: var(--color-darkgray-100);
  // color: #9D9D9D;
  text-align: center;
  display: inline-block;
  max-width: 305px;
}
.createAccountParent,
.groupParent1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.groupParent1 {
  gap: var(--gap-mini);
  padding: 10px;
}
.groupParent1:hover {
  background: #008aff1f;
  color: #fff !important;
  border-radius: 10px;
}
.searchTheBest {
  position: relative;
  font-size: var(--font-size-5xl);
  color: var(--color-darkgray-100);
  // color: #9D9D9D;
  text-align: center;
  display: inline-block;
  max-width: 255px;
}
.frameParent3,
.landingPage2Inner {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
}
// .frameParent3 {
//   flex-direction: row;
//   gap: 150px;
// }
.landingPage2Inner {
  border-radius: var(--br-3xs);
  background-color: var(--white);
  box-shadow: 0px 3px 20px 8px rgba(0, 0, 0, 0.05);
  flex-direction: column;
  padding: 41px 53px;
  box-sizing: border-box;
  font-size: var(--font-size-11xl);
  color: var(--color-gray-100);
}
.ceoCompany,
.jhonSmith {
  // color: #fff;
  font-weight: 500;
      font-size: 14px;
      // white-space: nowrap;
}
.frameContainer .jhonSmith {
  padding: 10px;
  font-weight: 400;
  font-size: 15px;
  color: #9D9D9D;
}
.ceoCompany {
  top: 34px;
  font-size: var(--font-size-lg);
  font-weight: 500;
  color: var(--blue);
  white-space: nowrap;
}
.namedesignations {
  // width: 144px;
  // height: 61px;
}
.avatarIcon,
.bgIcon,
.client {
  width: 60px;
  height: 60px;
  border-radius: 50px;
}
.bgIcon,
.client {
  width: 226px;
  height: 63px;
}
.bgIcon {
  width: 420px;
  height: 266px;
}
.loremIpsumIs,
.text {
  // width: 340px;
  // height: 112px;
}
.loremIpsumIs {
  line-height: 28px;
  font-weight: 500;
  display: inline-block;
}
.text {
  font-size: var(--font-size-lg);
}
.quoteIcon {
  height: 10.6%;
  width: 7%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  margin: 0 0 10px 0;
}
// .testimonial3 {

//   width: 420px;
// }
.namedesignations1 {
  width: 203px;
  height: 61px;
}
.client1,
.quoteIcon1 {
  width: 283px;
  height: 63px;
}
.quoteIcon1 {
  height: 8.6%;
  width: 10%;

  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.testimonial2 {
  width: 420px;
  height: 349px;
}
.mdCompany {
  font-size: var(--font-size-lg);
  font-weight: 500;
  color: var(--color-deepskyblue-100);
}
.client2,
.namedesignations2,
.text2 {
  width: 222px;
  height: 61px;
}
.client2,
.text2 {
  width: 302px;
  height: 63px;
}
.text2 {
  width: 340px;
  height: 112px;
  font-size: var(--font-size-lg);
  color: var(--white);
}
.quoteIcon2 {
  height: 8.6%;
  width: 10%;

  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  opacity: 0.3;
}
.testimonial1 {
  width: 420px;
  height: 349px;
}
.nextPrviousButton {
  width: 120px;
  height: 50px;
}
.seeWhatOthersContainer {
  line-height: 54px;
  font-weight: 500;
  display: inline-block;
  max-width: 532px;
}
//   .testimonial4,
//   .title {
//
//     top: 0;
//   }
.testimonial4 {
  left: 0;
  font-size: var(--font-size-lg);
  text-transform: uppercase;
  font-weight: 600;
  color: var(--color-deepskyblue-100);
}
.title {
  left: 1px;
  width: 532px;
  height: 140px;
  font-size: var(--font-size-13xl);
}
.testimonial {
  width: 100%;
  height: 550px;
  color: var(--dark);
}
.bgIcon3 {
  width: 100%;
  height: 739px;
}
.allRightsReserved {
  padding: 15px 0px;
  width: 100%;
  line-height: 25px;
  font-weight: 500;
}
.footerBottomChild {
  border-top: 1px solid #f3f3f3;
  box-sizing: border-box;
  width: 100%;
  height: 1px;
  opacity: 0.1;
}
.footerBottom {
  background: #232d39;
  width: 100%;
  text-align: center;
  font-size: 14px;
  color: #98a6ac;
  font-family: var(--font-poppins);
}
.btnBg {
  border-radius: 40px;
  background-color: var(--color-mediumseagreen);
  width: 307px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}
.paperPlane1Icon {
  width: 18px;
  height: 18px;
  overflow: hidden;
}
.subscribeNow {
  top: 17px;
  left: 78px;
  font-weight: 600;
}
.border,
.subscribeButton {
  max-width: 307px;
  height: 55px;
  display: flex;
  align-items: center;
  color: #fff !important;
  justify-content: center;
  margin: 20px 0 0 0;
}
.border {
  top: 0;
  border-radius: 40px;
  background-color: #525455;
  border: none !important;
}
.enterYourMail,
.mailIcon {
  top: 17px;
}
.enterYourMail {
  left: 60px;
  font-weight: 500;
}
.mailIcon {
  left: 25px;
  width: 20px;
  height: 20px;
  margin-left: 11px;
}
.inputField,
.signUpAnd {
  // left: 0;
  // width: 307px;
}
.inputField {
  top: 135px;
  height: 55px;
  color: var(--text-on-dark);
}
.signUpAnd {
  top: 0;
  line-height: 28px;
  font-weight: 500;
  margin-top: 10px;
  display: inline-block;
  font-size: 14px;
}
.text3,
.widgetTitle {
  text-align: left;
}
.text3 {
  // top: 54px;
  // width: 307px;
  height: 56px;
  color: var(--text-on-dark);
}
.widgetTitle {
  font-size: var(--font-size-5xl);
}
.newsletter {
  text-align: center;
  color: var(--white);
}
.learners {
  margin: 0;
  line-height: 28px;
}
.leadership {
  margin: 0;
  line-height: 38px;
}
.learnersLeadershipPartnersContainer {
  top: 0;
  left: 0;
  font-weight: 500;
}
.learnersLeadershipPartnersContainer .leadership{
  font-size: 14px;
}
.text4 {
  top: 53px;
  left: 0;
  width: 90px;
  height: 147px;
}

.widgetTitle1 {
  font-size: var(--font-size-5xl);
  color: var(--white);
}

.career {
  margin: 0;
  line-height: 28px;
  color: var(--white);
}
.quickLinks,
.text5 {
  left: 0;
}

.quickLinks {
  line-height: 28px;
  font-weight: 600;
}

.iconBorder {
  top: 0;
  left: 0;
  border-radius: 50%;
  border: 1px solid var(--text-on-dark);
  box-sizing: border-box;
  width: 36px;
  height: 36px;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
}
.twitter1 {
  top: 9px;
  left: 10px;
  display: inline-block;
  width: 16px;
  height: 20px;
}
.github,
.iconBorder2,
.twitter {
  margin-right: 10px;
  width: 36px;
  height: 36px;
}
.github,
.iconBorder2 {
  left: 112px;
}
.iconBorder2 {
  left: 0;
  border-radius: 50%;
  background-color: var(--blue);
  display: flex;
  align-items: center;
  justify-content: center;
}
.dribbble1 {
  display: inline-block;
  width: 16px;
  height: 20px;
}
.dribbble,
.dribbble1,
.fb1 {
}
.dribbble {
  margin-right: 10px;
  width: 36px;
  height: 36px;
  color: var(--white);
}
.fb1 {
  top: 8px;
  left: 13px;
  display: inline-block;
  width: 10px;
  height: 20px;
  color: #aeb7c2;
}
.fb,
.socialIcon {
  display: flex;
  width: 36px;
  height: 36px;
}

.inTheFastPacedContainer {
  line-height: 25px;
  font-weight: 500;
  margin-top: 15px;
  display: inline-block;
}
.inTheFastPacedContainer p{
  font-size: 14px;
}
.about,
.text6 {
  font-size: var(--font-size-lg);
  // font-family: var(--font-hk-grotesk);
}
.about {
  // top: 301px;
  // left: 60px;
  // height: 153px;
  font-size: var(--font-size-base);
  font-family: var(--font-font-awesome-5-brands);
}
.myvirtualpalLogo151Icon1 {
  top: 196px;
  left: 56px;
  width: 152px;
  height: 80px;
  object-fit: cover;
}
.footer {
  position: relative;
  // width: 100%;
  // height: 739px;
  background-size: cover;
  width: 100%;
  font-size: var(--font-size-lg);
  color: var(--text-on-dark);
  // font-family: var(--font-hk-grotesk);
  background-image: url(../../../public/assets/img/bg3.svg);
}
.lineDiv {
  top: 1947px;
  left: -10px;
  border-top: 20px solid var(--color-deepskyblue-100);
  box-sizing: border-box;
  width: 100%;
  height: 20px;
}
.landingPage2 {
  position: relative;
  background-color: #fdfdfd;
  width: 100%;
  // height: 6936px;
  // overflow: hidden;
  text-align: left;
  font-size: var(--font-size-5xl);
  color: var(--white);
  font-family: var(--font-poppins);
}

//   my css
.col-md-3.position-relative img:after {
  position: absolute;
  background: red;
  width: 100%;
  height: 100%;
  content: "";
  left: 0;
  top: 0;
}
p.theMostTalented {
  font-size: 35px;
  color: #222d39;
  line-height: 1.4;
  font-weight: 400;
  margin-bottom: 3rem;
}
.testimonial3 .text:hover {
  // background: #008affad !important;
  background: #20a2dc !important ;
  color: #fff !important;
 
}
.testimonial3 .text {
  width: 98%;
   background: #fff; 
  padding: 32px;
  // border-radius: 15px;
  // box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  margin: 5px;
  background: #008aff0a !important;
  border-radius:10px;
  margin: 0;
}
.testimonial3 .text:hover .read_m {
  background:#fff !important;
  color: #00a5d9 !important;
    border-color: 1px solid #00a5d9 !important;

}
.testimonial3 .text:hover .loremIpsumIs p {
  color: #ffff;
}
.testimonial3 .text:hover .loremIpsumIs{
  color: #fff !important;
}
.bg-green.text-center.p-4 {
  background: #f2fafa;
  margin-top: 7rem;
}
input.form-control.shadow {
  max-width: 560px;
  display: block;
  margin: auto;
  border: navajowhite;
  padding: 26px;
  margin-top: 3rem;
  text-align: center;
}
button.btn.btn-primary.Subscribe {
  width: 220px;
  height: 58px;
  font-weight: 600;
  font-size: 20px;
  margin-top: 3rem;
  margin-bottom: 2rem;
}
h2.news_letter {
  font-size: 45px;
  // color: #252525;
  color: #000;
  font-weight: 500;
  margin-top: 2rem;
}
.bg-green.text-center.p-4 p {
  // color: #9D9D9D;
  color: #000;
  font-size: 24px;
}
.text6 {
  position: relative;
  margin-top: 12rem;
}

// slider
.back_icon {
  position: absolute;
  background: #3aa0dc;
  width: 45px;
  height: 45px;
  border-radius: 50px;
  padding: 15px;
  justify-content: center;
  top: 89px;
  right: 66px;
  left: inherit;
  // opacity: 0;
}

.next_icon {
  position: absolute;
  background: #20a2dc !important;
  width: 45px;
  height: 45px;
  top: 89px;
  right: 6px;
  left: inherit;
  border-radius: 50px;
  padding: 15px;
  justify-content: center;
  // opacity: 0;
}
.test_icon {
  position: absolute;
  background: #3aa0dc;
  width: 53px;
  height: 49px;
  border-radius: 1px;
  padding: 15px;
  justify-content: center;
  top: -125px;
  right: 95px;
  bottom: inherit !important;
  left: inherit;
}
.test_icon_next {
  position: absolute;
  background: #3aa0dc;
  width: 53px;
  height: 49px;
  top: -125px;
  right: 20px;
  left: inherit;
  border-radius: 1px;
  padding: 15px;
  justify-content: center;
}
.test_icon_two{

    top: -110px;
}
.test_next_two{
  top: -110px;
}
@media(max-width:432px){
  .test_icon_two{

    top: -70px !important;
}
.test_next_two{
  top: -70px !important;
}
}
.home_carousel{
  position: relative !important;
}
.carousel {
  position: inherit;
  width: 100%;
}

img.invite_user {
  width: 65px;
  height: 65px;
  border-radius: 50px;
  object-fit: cover;
}
.left_description {
  padding-left: 5rem;
}
.invitation_offcanvas {
  width: 65% !important;
}
h4.heading_inv {
  font-size: 22px;
}
span.hourly_rate {
  // color: #5e6d55;
  color: #000;
}
.price_dollar {
  font-weight: 500;
  margin-bottom: 4px !important;
}
.mbSpace {
  margin-bottom: 10px !important;
}
p.hrs_ago {
  // color: #5e6d55;
  color: #000;
}
.main_sub_heading {
  font-size: 18px;
  font-weight: 500;
}
span.accepted.w-100.mt-3.d-block.text-center.mb-3 {
  padding: 12px;
}
p.mt-1.reviewsData {
  // color: #5e6d55!important;
  color: #000 !important;
  font-size: 12px;
}
span.active {
  background: #31a3d91c;
  padding: 5px 10px;
  border-radius: 20px;
  color: #47addd;
  text-transform: capitalize;
}
.drag_drop.p-4.text-center {
  background: #3b9acf21;
  border-radius: 10px;
}
input.mr-2.radio_space {
  position: relative;
  top: 4px;
}
.star_rating span {
  margin-top: 10px;
  font-size: 14px;
  color: green;
}
span.material-icons.text-sucess {
  color: green;
  position: relative;
  top: 3px;
}
.btn-outline-danger {
  color: #3d3d3d !important;
  border-color: #4c4c4c !important;
}

@media screen and (max-width: 991px) {
  .logoDesign {
    text-align: center;
    font-size: 20px;
  }
  .postAProjectWrapper,
  .scheduleADemoWrapper {
    width: 100%;
  }
  .ph_btn_align {
    margin-top: 0px !important;
    display: block !important;
  }
  // .ph_btn_align div {
  //   margin-bottom: 10px !important;
  // }
  .scheduleADemoWrapper.mr-2 {
    margin-bottom: 10px;
    padding: 15px;
    width: 200px;
}
.postAProjectWrapper {
  width: 200px;
  padding: 15px;
}
.highestBid500Parent {
  flex-wrap: wrap;
}
button.scheduleADemoWrapper.b-0 {
  width: auto;
}
.ceoCompanyy {
   padding-left: 0px; 
   font-size: 20px;
}
.frameContainer .jhonSmith {
//  padding: 0px; 
 font-size: 14px;
}


  .inputField {
    margin-top: 2rem;
  }
  .bg-green.text-center.p-4 p {
    // color: #9D9D9D;
    color: #000;
    font-size: 16px;
  }
  h2.news_letter {
    font-size: 28px;
  }
  .text6 {
    margin-top: 0px;
  }
  // .text6.col-md-4.pl-5 {
  //   margin-top: 0px;
  //   position: relative;
  //   margin-top: 14rem;
  //   padding-left: 15px !important;
  // }
  div#test {
    max-height: 420px;
    overflow: auto;
  }
  .landingPage2Inner {
    padding: 41px 25px;
    text-align: center;
  }
  .findTheBestVirtualAssistanParent {
    text-align: left;
    margin: 20px 0 0 0;
  }
  .back_icon,
  .next_icon {
    top: 150px;
  }
  p.theMostTalented {
    font-size: 18px;
  }
  .test_icon {
    width: 40px;
    height: 30px;
    top: -140px;
    right: 70px;
  }
  .test_icon_next {
    width: 40px;
    height: 30px;
    top: -140px;
    right: 10px;
  }
  .findTheBest {
    max-width: 572px;
    color: #20a2dc;
    font-size: 28px;
    margin: 0 0 0 auto;
}
  .loremIpsumDolorContainer {
    font-size: 14px;
  }
  .theLatestProjects {
    font-size: 18px;
  }
  // .theLatestProjectsWorkParent {
  //   margin-top: 0px !important;
  //   padding: 14px 0px;
  
  // }
  .materialUiParent {
    margin-bottom: 15px;
  }
  .frameDiv,
  .frameParent1 {
    gap: 25px;
  }
  // .frameContainer {
  //   gap: 0px !important;
  //   padding: 14px 5px;
  //   flex-wrap: wrap;
  // }
  .rectangleParent {
    position: relative;
  // max-width: 390px;
  width: 100% !important;
    max-height: 472px;
   
    margin-bottom: 15px;
  }
}


.slick-slider{
  margin: 0 !important;
  padding: 0 !important;
  width: 100% !important;
}

.cat_left_arrow {
  position: absolute;
  top: -148px;
  right: 92px;
  cursor: pointer;
  color: #fff;
  display: flex;
  width: 50px;
  height: 50px;
  border-radius: 100px;
  padding: 15px;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  justify-content: center;
  align-items: center;
}
.cat_left_arrow:hover {
  
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: .9;
background: #20a2dc !important;

}
.cm_ico {
  
  color: #5ca7df !important;

}
.cat_left_arrow:hover .cm_ico {
  
  color: #fff !important;

}

.cat_right_arrow {
  position: absolute;
  top: -148px;
  right: 16px;
  cursor: pointer;
  color: #fff;
  // background: #3aa0dc54 !important;
  background-color: #20a2dc !important;
  display: flex;
  width: 50px;
  height: 50px;
  border-radius: 100px;
  padding: 15px;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  padding: 15px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;

}

.material-icons.cat_right_arrow .cm_ico {
  color: #fff !important;
}
.cat_right_arrow:hover {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: .9;
background: #20a2dc !important;

}
.cat_right_arrow:hover .cm_ico{
  color: #fff !important;
}
// Time Clock Entries table
.limit_dats {
  max-width: 200px !important;
  white-space: normal !important;
}

.new_range .dateRangePicker {
  max-width: 246px;
}
.home_margin {
  margin-top: 190px;
}
.WorkParent_m{
  margin-bottom:52px
}
button.scheduleADemoWrapper.b-0 {
  border: none;
  margin-top: 50px;
}

.slick-dots li.slick-active button:before {
  color: #20A2DC !important;
}
.slick-dots li button:before {
  font-size: 23px !important;
}
.slick-list{
  margin-bottom: 50px !important;
}
.chooseDifferentCategoryContainer2 {
  font-weight: 400;
  font-size: 32px;
}
.test_p {
  font-size: 18px;
  color: #20A2DC;
  margin-bottom: 10px;
  font-weight: 500;
}
.textlimittest{
    width: 200px;
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    margin: 0;
}
// .home_in{
//   border-radius: 5px !important;
// }
.m2 {
  border-radius: 0px !important;
}
.m2 {
  border-radius: 0px !important;
}
.clls {
  position: absolute;
  top: 104px;
  color: #fff;
  // left: 29%;
  font-weight: 500;
  margin: 0;
}
.jhonSmithh {
  font-size: 19px;
}
.ceoCompanyy {
  font-size: 18px;
  padding-left: 10px;
  font-weight: 500;
}

@media(max-width:992px)
{
  .Assistants {
    font-size: 13px;
    line-height: 22px;
}

}

// navbar
// .drop_it {
//   position: relative;
//   top: 75px;
// }
@media(max-width:724px){
  .recentlyPostedWorksContainer {
    font-size: 27px;
}
.next_div {
  font-size: 27px;
}
.chooseDifferentCategoryContainer2 {
  font-size: 27px;
}
.frameContainer .jhonSmith {
  padding-left: 15px;

}
}
@media(max-width:480px){
  .scheduleADemo{
    font-size: 12px;
  }.scheduleADemoWrapper.mr-2{
    width: 155px;
}
.postAProjectWrapper {
  width: 155px;
}
.frameContainer .jhonSmith{
  padding-left: 0;
  font-size: 13px;
}
.logoDesign {
  font-size: 17px;
}
.firstYouHave , .searchTheBest{
  font-size: 12px;
}
.findTheBest {
  font-size: 20px !important;
  line-height: 29px;
}

.home_margin {
  margin-top: 30px;
}
.cat_left_arrow {
  right: 70px;
  width: 40px;
  height: 40px;
}

.cat_right_arrow {

  width: 40px;
  height: 40px;
}
.materialUiParent {
  margin-bottom: 0px;
}
.WorkParent_m {
  margin-bottom: 10px;
}
.needAProfessional {
  font-size: 12px;
}
span.span1 {
  font-size: 13px;
}
p.text-primary.apply_Now {
  font-size: 12px;
}
button.scheduleADemoWrapper.b-0 {
  margin-top: 40px;
  font-size: 12px;
}
.theLatestProjects {
  font-size: 15px;
}
.next_div, .works_t {
  font-size: 19px;
}
.ceoCompanyy {
  font-size: 16px;
  padding-left: 0;
}
.chooseDifferentCategoryContainer2 {
  font-size: 19px;
}
.border {
  width: 290px;
}
.recentlyPostedWorksContainer.WorkParent_m span {
  font-size: 19px;
}
.recentlyPostedWorksContainer span {
  font-size: 19px !important;
}
.slick-initialized .slick-slide {
   padding: 0 ; 
}
.homes_row .col-md-4 {
  padding: 0px;
  } 
  .groupItem {
    height: 150px;
    object-fit: contain;
} 
.groupParent1{
  width: 100%;
}
// .phonemx{
//   padding: 0 !important;
// }
.arrtoright{
 height: 22px;
}
p.theMostTalented {
  font-size: 15px !important;
  margin-bottom: 1rem;
}
.bg-green{
  margin-top: 0 !important;
}
h2.news_letter {
  font-size: 22px;
  margin-top: 1rem;
}
.bg-green.text-center.p-4 p {
  font-size: 13px;
}
input.form-control.shadow {
  margin-top: 2rem;
}
.loremIpsumIs p {
  font-size: 13px;
  line-height: 22px;
}
button.btn.btn-primary.Subscribe {
  font-size: 16px;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.homes_row .col-md-4.phonemx  {
  padding-right: 20px !important;
  padding-left: 20px !important;
}
.rectangleParent {
  padding-bottom: 0;
}

}
