/* styles.css */

.schedule-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  th, td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  th {
    background-color: #f2f2f2;
  }
  
  input {
    width: 100%;
    box-sizing: border-box;
  }
  
  /* Optional: Add some styling for better visibility */
 
  
  h1 {
    text-align: center;
  }
  input{
    padding-left: 5px;
  }
  
  @media(max-width:991px){
    select.form-control.widform {
      width: fit-content;
  }
  }