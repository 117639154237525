.main12{
    display: flex;
    translate: 88% 50%;
    width: 100vh;
    height: 56vh;
  
}
.backcls{
    background-image:url('https://wallpapers.com/images/hd/virtual-meeting-background-srj07387lg3e219s.jpg');
    background-position:100% 100%;
    height:"100%";
}