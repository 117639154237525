.loginimg {
    width: 100%;
}
.arrow_right{
    font-size: 18px;
    margin-right: 5px;
}
a.right_home:hover{
    color: #000 !important;
    text-decoration: none;
}
a.right_home {
    // position: absolute;
    position: fixed !important;
    display: flex;
    align-items: center;
    left: 15px;
    top: 15px;
    font-size: 14px;
    background: #fff;
    border: 1px solid;
    padding: 5px 10px;
    border-radius: 32px;
}
button.btn.btn-outline.socialBtn img {
    width: 16px;
}
.login_img {
display: none !important;
}
.borderCls {
    height: 1px;
    width: 100%;
    // background: #DCDCDC; 
   text-align: center;
}
span.or {
    position: relative;
    top: -12px;
    background: #fff;
    padding: 2px;
    // color: #898989;
    color: #000;
}
form.p-3.rounded.shadow {
    border-top: none;
}


.google_id {
    box-shadow: 0px 0px 5px #80808059;
}

img.loginimg.w-100 {
    height: 100%;
    object-fit: cover;
}
// form.centerLogin {
//     max-width: 418px;
//     display: block;
//     margin: auto;
// }
img.logimg {
    width: 100%;
    max-width: 167px;
    max-height: 100px;
    object-fit: cover;
}


.login-wrapper {
    .mainfromclss {
        max-width: 900px;
    }
}
img.logo {
    width: 300px;
    height: auto;
 
}
.logo_img{
    padding: 0rem 3rem;
}
.banner_img{
    background-image: url(../../../public/assets/img/loginimg.png);
    height: 100vh;
    width: 100%;
    // background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 4rem 0rem;
}
.loginLeftImg h3 {
    position: absolute;
    bottom: 10px;
    font-size: 36px;
    font-weight: 600;
    line-height: 50px;
    color: #fff;
    padding: 4rem 2rem;
   

}
 
p.accopunt {
    text-align: right;
    // position: absolute;
    // right: 55px;
    // padding: 4rem 1rem;
    font-size: 14px;
    font-weight: 400;
    // top: 31px !important;
}
p.accopunts {
    position: absolute;
    padding: 0rem 1rem;
    font-size: 13px;
    font-weight: 400;
    width: 100%;
    text-align: left;
    bottom: 0;
    max-width: 454px;
    // color: #84818A;
    color: #000;
    word-spacing: 3px;
}
a.sign_up {
    text-decoration: none;
    font-size: 14px;
    color: #6dc279;
}
h3.text-left.lgtext {
    font-weight: 600;
    font-size: 24px;
}
.bginput { 
    border-radius: 8px !important;
    border: 1px solid #E8E8E8;
    height: 50px;
}
a:hover {
    color: #27B7F0 !important;
}
a.text-black {
    color: #000 !important;
    text-decoration: none;
}
a.text-primary:hover, a.text-primary:focus {
    color: #27B7F0 !important;
    text-decoration: none;
}
.text-primary {
    color: #27B7F0 !important;
    font-size: 14px;
}
.forget{
    text-align: left;
    margin: 1rem 0px;
}

.title-around-span {
    position: relative;
    text-align: center;
    color: #484848;
    font-size: 14px;
  
    &:before {
      content: "";
      display: block;
      height: 1px;
      width: 100%;
      position: absolute;
      left: 0;
      top: 50%;
      background-color:#E8E8E8;
    }
  
    span {
      position: relative;
      z-index: 1;
      padding: 0 15px;
      background: #ffff;
    }
  }


  .google_logo {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
}
img.gogle_img {
    width: 30px;
    height: 30px;
    margin: 0px 5px;
}
img.gogle_imgs {
    width: 25px;
    height: 24px;
    margin: 0px 5px;
}
a.btn.btn-outline.google_id img {
    width: 16px;
    margin-right: 0px;
}
.google_id {
    border: 1px solid #E8E8E8;
    width: 173px;
    height: 48px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
}
.google_heading {
    font-size: 16px;
    font-weight: 500;
    color: #202020;

}
.google_id {
    // width: 178px;
    border: 1px solid #E8E8E8;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
}

 .checkBox {
    position: relative;
    top: 4px;
}
.clickBox{
    color: #898989;
    font-size: 12px;
}


@media only screen and (min-width:1001px) and (max-width:1060px) {
    img.logo {
        width: 200px !important;
        height: auto;
    }
    .logo_img {
        padding: 0rem 2rem;
    }
    .loginLeftImg h3 {
        position: absolute;
        bottom: 10px;
        font-size: 29px !important;
        line-height: 53px !important;
        color: #fff;
        padding: 2rem 2rem;
    }
}

@media only screen and (min-width:768px) and (max-width:1000px) {
    img.logo {
        width: 200px !important;
        height: auto;
    }
    .logo_img {
        padding: 0rem 2rem;
    }
    .loginLeftImg h3 {
        position: absolute;
        bottom: 10px;
        font-size: 29px !important;
        line-height: 35px;
        color: #fff;
        padding: 2rem 2rem;
    }


}



@media only screen and (min-width:320px) and (max-width:767px)  {
    .banner_img{
        display: none;
    }
    .logo_image { 
        margin: 0rem 0rem 0rem 0rem;
    }
    .right_side {
        padding: 32px;
    }
    img.logos {
        width: 200px;
    }
    
    .login_img {
        text-align: center;
        position: absolute;
        display: block !important;
        top: 44px;
    }
    // form.centerLogin {
    //     margin: 0rem auto !important;
    // }
    p.accopunt {
        top:116px !important;
    }
    // form.centerLogin {
    //     max-width: 250px !important;
    // }
    .modal-body.p-5 {
        padding: 10px !important;
    }
    label.form-check-label {
        font-size: 12px;
    }
    .lgtext {
        font-weight: 600;
        font-size: 18px;
    }
    .top_section {
        margin-top: 8rem;
    }
    .top-sect-new{
        margin-top: 0 !important;
    }
   
    .remove_space_forgot{
        margin-top: 0rem !important;
    }    
}
@media only screen and (max-width:420px)  {
    .checkbox_size input {
        width: 35px !important;
        height: 35px !important;
    }
}
.google_id {
    width: 100% !important;
    background-color: #e4efef6b;
    display: flex;
    padding: 0 !important;
    display: flex;
    justify-content: start;
}
.google_img {
    box-shadow: 0px 0px 5px #80808059;
    border: 1px solid #E8E8E8;
    height: 48px;
    width: 48px !important;
    background: #fff;
    border-radius: 100%;
    object-fit: contain;
    padding: 4px;
}
@media(max-width:1300px){
    .main_signupscrolling{
        height: auto !important;
    }
}