.lineHeight {
  margin-bottom: 1.8rem;
    align-items: center;
}
.ml-2.right_pro_data {
    line-height: 1;
}
small.ex_level {
    color: #a1a1a1;
}
@media(max-width:480px){
    .h3font{
        font-size: 16px;
    }
}