@import "./mixins";

// Colors
@import "./colors";
@import "./table";

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=HK+Grotesk:wght@500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Abel&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;1,100;1,200;1,300;1,400&display=swap");
body {
  margin: 0;   
  line-height: normal;
  overflow-x: hidden !important;
  font-family: "Poppins", sans-serif;
}
img.delete_icon {
  width: 80px;
}
img.user_img {
  width: 70px;
  height: 70px;
  object-fit: cover;
}
.product_details {
  line-height: 21px;
}
.Sales_row {
  background: #fff;
}
.Sales_row p {
  color: #111827;
  font-size: 16px;
  margin-bottom: 10px;
  
}

.Sales_row h2 {
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  font-size: 20px;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
}
.Sales_row p {
  color: #111827;
  font-size: 16px;
  margin-bottom: 10px;
}
.green_icon {
  background: #04785714;
  color: #047857 !important;
}
span.green_icon span.material-icons.mr-1 {
  line-height: 0 !important;
  position: relative;
  top: 5px;
}
.green_icon,
.red_icon {
  border-radius: 30px;
  font-size: 12px;
  margin-right: 5px;
  padding: 5px; 
}
span.text_color {
  color: #6b7280;
  font-size: 12px;
}
.Sales_box,
.Sales_row {
  border-radius: 12px;
  padding: 15px;
}
.tox-notifications-container {
  visibility: hidden;
}
.cat_drop_multi .searchWrapper {
  padding: 8px 23px !important;
}
.find_title {
  font-size: 20px;
}
.mainfind {
  border-radius: 12px;
  border: 1px solid #31a3d903;
}
li.shadow.p-4.pointer.border-rounded.font-weight-bold {
  border-radius: 20px;
  padding: 50px 10px !important;
  border: 2px solid #e6e6e6;
  font-weight: 200 !important;
  box-shadow: 0 0.1rem 0.3rem rgba(0, 0, 0, 0.1) !important;
}
.btSmall {
  padding: 4px 20px !important;
  cursor: pointer;
}
ul.col-md-4 li {
  list-style: none;
}
li.shadow.p-4.pointer.font-weight-bold:hover {
  background: #44a1d9;
  color: #fff;
  transition: 0.5s;
}

span.rejected {
  background: #ff000014;
  padding: 5px 10px;
  border-radius: 20px;
  color: red;
  text-transform: capitalize;
}
input.enterEmail {
  background: transparent;
  box-shadow: none !important;
  border: none !important;
  color: #fff;
}
span.accepted {
  background: #2181001f;
  padding: 5px 10px;
  border-radius: 20px;
  color: green;
  text-transform: capitalize;
}
.close_icon {
  position: relative;
  top: 2px;
}
span.pending {
  background: #f9a71026;
  padding: 5px 10px;
  border-radius: 20px;
  color: orange;
  text-transform: capitalize;
}
/* another way */
.tox-notifications-container {
  display: none;
}
:root {
  /* fonts */
  --font-hk-grotesk: "Poppins", sans-serif;
  --font-font-awesome-5-brands: "Font Awesome 5 Brands";
  --font-poppins: "Poppins", sans-serif;

  /* font sizes */
  --font-size-lg: 18px;
  --font-size-base: 16px;
  --font-size-5xl: 18px;
  --font-size-13xl: 32px;
  --font-size-11xl: 30px;
  --font-size-37xl: 42px;
  --font-size-17xl: 36px;
  --font-size-xs: 12px;

  /* Colors */
  // --color-gray-100: #252525;
  --color-gray-100: #000;
  --color-gray-200: rgba(0, 0, 0, 0.59);
  --color-gray-300: rgba(0, 0, 0, 0.5);
  --color-gray-400: rgba(0, 0, 0, 0.3);
  --color-deepskyblue-100: #20a2dc;
  --text-on-dark: #adb7c2;
  --white: #fff;
  --blue: #008aff;
  --color-mediumseagreen: #63c17c;
  // --color-darkgray-100: #9d9d9d;
  --color-darkgray-100: #000;
  --dark: #222d39;
  --color-cornflowerblue: #1e88e5;

  /* Gaps */
  --gap-mini: 15px;
  --gap-8xs: 5px;
  --gap-56xl: 75px;    
  --gap-11xl: 30px;

  /* Paddings */
  --padding-xl: 20px;
  --padding-5xl: 24px;

  /* Border radiuses */
  --br-10xs: 3px;
  --br-3xs: 10px;
  --br-xl: 20px;
  --br-11xl: 30px;
}
.chip {
  background: #00a5d9 !important;
  border-radius: 59px !important;
  color: #fff !important;
  font-size: 12px !important;
  padding: 6px !important;
  font-weight: 500;
  padding-left: 12px !important;
}

// .searchbox {
//   border-radius: 5px 0px 0px 7px !important;
// }
.css-13cymwt-control {
  border-radius: 50px !important;
}
.selectbox {
  border-radius: 50px !important;
}

.searchbtn {
  border-radius: 0px 5px 5px 0px !important;
}
span.material-icons.increase {
  font-size: 35px;
  color: #31a3d9;
}
.bg_blue_port {
  background: #5cc1f230;
  border: none;
}
img.img_person {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  display: block;
  margin: auto;
}
p.bg_invite {
  background: #4a9edc2b;
  padding: 10px;
  border-radius: 5px;
}

.makeitdisable {
  cursor: not-allowed !important;
}

.btn-sm {
  padding: 5px 12px !important;
  font-size: 14px !important;
}
.btn-vsm {
  padding: 3px 11px !important;
  font-size: 10px !important;
}

// signup css
h3.text-left.lgtext {
  font-weight: 600;
  font-size: 24px;
  // color: #444444;
  color: #000;
}
.form_home {
  margin-top: 2rem;
  // background: #0066a914;
  padding: 2rem;
  border-radius: 10px;
  // box-shadow: 0px 0px 16px #c2bdbd;
}
.red {
  color: red;
}

:focus-visible {
  outline: -webkit-focus-ring-color auto 0px;
}

:where(.css-dev-only-do-not-override-1m62vyb).ant-select:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  // position: relative;
  // background-color: initial;
  // border: none;
  // transition: inherit;
  border: 1px solid #dcdcdc;
}

.signup_tab {
  border: none !important;
}

.signuptab {
  border: none !important;
  background: #fff;
  border-radius: 3px;
  height: 39px;
}

.signup-tab_cls {
  background: #fff;
  margin-left: 12px;
  border-radius: 3px;
  border: none !important;
  height: 39px;
}

:where(.css-dev-only-do-not-override-1m62vyb).ant-select-single:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  width: 352px;
  height: 32px;
  padding: 0 11px;
  background: initial !important;
}
p.profile_data p {
  margin-bottom: 0px;
}
.marginleft {
  width: 100%;
}
:where(.css-dev-only-do-not-override-190m0jy).ant-select-single:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  width: 100%;
  height: 38px !important;
  padding: 0 11px;
}
.form_profile {
  margin-top: 2rem;
  // background: #0066a914;
  padding: 2rem;
  border-radius: 10px;
  // box-shadow: 0px 0px 16px #c2bdbd;
}
.output h6,
h5 {
  margin-bottom: 0px;
}
.output h6 {
  font-size: 13px;
}
.output {
  margin-bottom: 10px;
  border-bottom: 2px solid #cccccc2b;
  background: #4a9edc1a;
  padding: 10px;
}
a.sign_up:hover {
  color: #000 !important;
}
a:hover {
  text-decoration: none;
}
.main_signup {
  background-image: url("../../public/assets/img/auth.jpg");
  height: 100%;
  background-attachment: fixed;

  background-repeat: no-repeat;
  background-size: cover;
}
.top_section {
  background-image: linear-gradient(to left, #2062ad, #0c8e99, #24a2db);
  padding: 3px;
  border-radius: 40px;
}
img.logo_name {
  width: 100%;
  max-width: 150px;
  max-height: 65px;
  object-fit: contain;
}
.logo_image {
  display: flex;
  justify-content: center;
  margin: 0rem 0rem 1rem 0rem;
}
.right_side {
  background-color: #fff;
  height: 100%;
  padding: 1rem;
  border-width: 0px;
  border-radius: 32px;
  // border-image-source: linear-gradient(to left, #2062AD, #0C8E99, #24A2DB );
}
input.form-control.mb-0.bginput.changes {
  background-color: #fff !important;
  color: #000 !important;
  border-color: #d2cece !important;
  border: 1px solid #d2cece !important;
  border-radius: 38px !important;
  height: 40px;
}

.form-check-input {
  border-color: #86b7fe;
  outline: 0;
  // box-shadow: 0 0 0 0.25rem rgba(13,110,253,.25);
  background-color: #00a5d994;
}
input.form-control.bginput.chnages {
  background-color: #fff !important;
  color: #000 !important;
  border-color: #d2cece !important;
  border: 1px solid #d2cece !important;
  border-radius: 38px !important;
  height: 40px;
}
.nav-tabs {
  border-bottom: 1px solid #ffffff;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #f9f9f9 !important;
  background-color: #00a5d9;
  border-color: #dee2e6 #dee2e6 #fff;
  border-radius: 4px;
}
.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border-color: #fff #fff #fff;
  color: #000;
}

form.job_search {
  width: 100%;
}

b.text-primary.font-weight-bold.text-capitalize.pointer {
  font-size: 16px;
  font-weight: 500 !important;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 500px;
}
@media (max-width: 820px) {
  b.text-primary.font-weight-bold.text-capitalize.pointer {
    max-width: 100px;
  }
}

// end
.holidays {
  display: block;
  font-size: 14px;
  margin-bottom: 9px;
  position: relative;
  padding-left: 15px;
  cursor: pointer;

  input {
    margin-right: 10px;
  }

  &.active {
    color: $primaryColor;
  }

  > .bold {
    font-weight: 500;
  }

  // &::before{
  //     content: "";
  //     height: 5px;
  //     width: 5px;
  //     position: absolute;
  //     background-color: #000;
  //     left: 0;
  //     top:8px;
  //     border-radius: 50%;
  // }
}

a:disabled,
.disabled {
  pointer-events: none;
}

.paginationWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;

  > span {
    font-size: 13px;
    // color: #84818A;
    color: #000;
  }
}

.alignTopDate div.ant-picker-input {
  top: -12px;
}

.react-pagination-js-default {
  ul {
    display: flex;
    align-items: center;
    column-gap: 10px;

    > li {
      margin: 0 !important;
      font-size: 13px !important;
      padding: 7px 13px !important;

      &:first-child {
        padding: 0 !important;

        &:not(.disabled) {
          color: #1f7bf4 !important;
        }

        &::after {
          content: "< Previous";
        }

        a {
          display: none;
        }
      }

      &:last-child {
        padding: 0 !important;

        &:not(.disabled) {
          color: #1f7bf4 !important;
        }

        &::after {
          content: "Next >";
        }

        a {
          display: none;
        }
      }

      &.page {
        border-color: #0096fb !important;
        background-color: transparent !important;
        box-shadow: none !important;
        font-weight: 400 !important;
      }

      &.is-active {
        font-weight: 500 !important;
        background-color: #fff !important;
        border-color: #0096fb !important;
        box-shadow: none !important;
        text-shadow: none !important;
        color: #202020 !important;
      }
    }
  }
}

body {
  font-family: $font1 !important;
}

a {
  color: #00a5d9;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font2;
}
.form-control:focus {
  box-shadow: none !important;
}

.contract_box:hover {
  padding: 15px;
  border-radius: 10px;
  border: 1px solid #0000001a;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  transition: 0.9s;

}
.contract_box {
  
  padding: 15px;
  border-radius: 10px;
  margin-bottom: 20px;
  cursor: pointer;
  background: #e4eff599;
  border: 1px solid #0000001a;
}
.contract_box  small {
  margin: 0  0 0 auto;
  display: block;
  color: #af0c00;
}
.contract-title {
  display: flex;
  align-items: flex-start;
  gap:4px;
  justify-content: space-between;
}
span.head-sub i {
  color: #0000006e;
  font-size: 12px;
}
svg.active-icons {
  color: green;
  font-size: 14px;
  margin: 0px;
}
span.actice-text {
  font-size: 13px;
  color: green;
  font-weight: 600;
}
.contract_box .contract-title h5 {
  font-size: 18px !important;
  line-height: 23px;
  padding-bottom: 1px;
  border-bottom: 1px solid transparent;
  margin-bottom: 18px;
  display: inline;
  text-transform: capitalize;
  font-weight: 600;
  color: #1771b8 !important;
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.contract_box:hover .contract-title h5 {
  border-bottom: 1px solid  #1771b8 !important ;
}
.tabs-btn button {
  width: 50%;
}
.tabs-btn {
  background: #ffffff;
  padding: 2px;
  border-radius: 5px;
  border: 1px solid #00000036;
  width: 317px;
  display: flex;
}
.tabs_changes .btn-primary, .tabs_changes{
  border-radius: 46px !important; 
}
.tabs_changes{
  background-color: aliceblue;
}

.tabs-btn .btn-primary {
  border-radius: 3px ;
  padding: 6px 6px !important;
}

.tabs-btn .btn-secondary {
  background: transparent;
  color: #0000008a;
  border: 0;
}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  color: #f9f9f9 !important;
  background-color: #00a5d9 !important;
  border-color: #dee2e6 #dee2e6 #fff;
  border-radius: 4px;
}
span.head-sub {
  width: 160px !important;
  display: inline-block;
  color: #0000008a;
  font-size: 15px;
}
.active-span {
  display: flex;
  justify-content: end;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 0 0 auto;
  background: #33c360 !important;
  padding: 2px 10px !important;
  border-radius: 4px !important;
  color: #ffffff !important;
  text-transform: capitalize;
  font-size: 12px !important;
}
input,
select,
.input-group-text {
  border-radius: 50px !important;
}
textarea.form-control {
  border-radius: 12px;
}
.input-group
  > .input-group-append:last-child
  > .btn:not(:last-child):not(.dropdown-toggle),
.input-group
  > .input-group-append:last-child
  > .input-group-text:not(:last-child),
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.input-group
  > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(
    .valid-feedback
  ):not(.invalid-tooltip):not(.invalid-feedback) {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3),
.input-group:not(.has-validation)
  > .form-floating:not(:last-child)
  > .form-control,
.input-group:not(.has-validation)
  > .form-floating:not(:last-child)
  > .form-select,
.input-group:not(.has-validation)
  > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(
    .form-floating
  ) {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
label {
  font-size: 14px;
  font-weight: 500;
  // text-transform: capitalize;
  color: #202020;
}
span.timer {
  padding: 10px;
  background: #31a3d92b;
  width: 50px;
  display: inline-block;
  text-align: center;
  border: 1px solid #cccccc6b;
  margin-left: 13px;
  border-radius: 6px;
}
.col_side div span {
  font-size: 15px;
  font-weight: 300;
  color: #202020;
}

i.fa {
  cursor: pointer;
}

.dateRangePicker {
  width: 100% !important;
  border-radius: 8px;
  padding: 21px 10px;
  border: 1px solid #dcdcdc;
  height: calc(1.5em + 0.75rem + 2px);
}
.react-tooltip {
  max-width: 280px;
  word-break: break-all;
  white-space: initial;
  background: #fff !important;
  color: #000 !important;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.optionContainer {
  border: none !important;
  margin-bottom: 4px;
}

.min-sidebar ~ div > .main-sidebar a.col_side:hover {
  justify-content: center;
  margin-right: 0px !important;
}

.multiSelectContainer li:hover {
  background-color: #31a3d92b !important;
  color: #31a3d9 !important;
  border-radius: 8px;
}
li.groupHeading {
  // color: #908e8e;
  color: #000 !important;
}
// .optionListContainer {
//   background: #fff;
//   margin-top: 1px;
//   position: absolute;
//   width: 100%;
//   z-index: 2;
//   border: none;
//   border-radius: 14px;
//   padding: 0.7rem 0.5rem;
//   box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important;
// }

.optionListContainer {
  background: #fff;
  margin-top: 1px;
  position: absolute;
  width: 199px !important;
  z-index: 99999999999999 !important;
  border: none;
  border-radius: 14px;
  padding: 0.7rem 0.5rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.Postscls {
  border-radius: 8px !important;
}
.selectbox {
  border-radius: 50px !important;
  // color: #808080;
  color: #000;
  font-size: 15px;
  width: 150px;
}
.star {
  color: red;
  // display: none;
  // color: #202020;
}

.action_icons {
  display: flex;
}

a.edit_icon {
  border: 1px solid #dcdcdc;
  height: 30px;
  width: 30px;
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 5px;
  margin-right: 6px;

  &:hover {
    text-decoration: none !important;
  }
}

.mainarea {
  background: #f6f6f6;
  padding: 27px;
}

span.edit_icon {
  border: 1px solid #dcdcdc;
  height: 30px;
  width: 30px;
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 5px;
  margin-left: 3px;
  cursor: pointer;
}

i.material-icons.edit {
  font-size: 15px;
  font-weight: 600;
  // color: #898989;
  color: #000;
}

i.material-icons.delete {
  font-size: 15px;
  font-weight: 600;
  // color: #898989;
  color: #000;
}

i.fa.fa-arrow-left {
  color: #000;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f9f9f9 !important;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-bottom: 1px solid #dee2e6 !important;
 
  // text-transform: capitalize;
  white-space: nowrap;
}

i.fa.fa-eye.eye {
  color: #3574ac;
}
.closeicon {
  position: relative;
  top: 27px;
  left: -1rem;
  z-index: 999;
}

p.accopunt.text-center {
  // color: gray;
  color: #000;
  font-size: 15px;
  margin-bottom: 0px;
  padding: 5px;
}
// .google_id{
//   width: 50px !important;
// }
.lgtext {
  font-weight: 600;
  font-size: 24px;
  // color: #444444;
  color: #000;
}

.space_canvas {
  width: 80% !important;
  border-radius: 20px 0px 0px 20px;
}

.skills_txt {
  font-size: 12px;
}

p.bold_hedding {
  // color: gray;
  color: #000;
  font-size: 15px;
  font-weight: 500;
}
p.gay_title {
  // color: gray;
  color: #000;
  font-size: 13px;
}
.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 18px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

.inputWrapper {
  position: relative;

  .fa {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    // color: #84818A;
    color: #000;
  }
}

.modal-title {
  text-transform: capitalize;
}

input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  // color: #84818A !important;
  color: #000 !important;
  opacity: 1 !important;
  /* Firefox */
}

input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #000 !important;
  opacity: 1 !important;
}

.loaderDiv {
  z-index: 99999;
  backdrop-filter: blur(2px);
  align-items: center;
  justify-content: center;
  display: flex;
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
}

.pageLoader {
  max-width: 50px;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  // color: #A0A4A8;
  color: #000;
  opacity: 1;
  font-size: 14px;
  font-weight: 400;
  /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  // color: #A0A4A8;
  color: #000;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  // color: #A0A4A8;
  color: #000;
}

main.main {
  background-color: #f6f6f6 !important;
}

// Loader start
img.loaderlogo {
  max-width: 50px;
  // animation: rotation 3s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

// Loader end
.gogl {
  // color: grey;
  color: #000;
}
.btn {
  text-transform: capitalize;
  font-weight: 500;
  // padding: 8px 18px;
  padding: 6px 16px;
  font-size: 14px;
  border-radius: 50px;
  // border-radius: 10px;
}
i.fa.fa-edit.mr-2 {
  color: #31a3d9;
}
.lokm {
  border-radius: 0px !important;
}
button.btn.btn-outline-primary.header_like {
  padding: 9px 9px;
  font-size: 22px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  border: 1px solid #73737385;
  background: #ffff !important;
}
.col-md-12.border-top.border-bottom.mt-3.pt-3.mb-4.pb-3 {
  background: #31a3d903;
  // margin-bottom: 0px !important;
  margin-top: 0px !important;
  border-radius: 20px;
  margin-top: 0px !important;
  border-radius: 20px;
}

// job search

.top_box {
  border: 1px solid #dedede;
  border-radius: 20px;
  padding: 16px 19px;
  height: 137px;
}
.fav_fill {
  color: #00a5d9 !important;
}
h2.best_match {
  font-size: 24px;
}
.card-header.bg-white {
  border-radius: 15px 15px 0px 0px !important;
}
.daycls {
  font-size: 20px;
  font-weight: 600;
}

.messagcls {
  font-size: 26px;
  font-weight: 600;
  color: #000000d9;
}

.sidebox {
  border-radius: 20px;
}

.price_locals {
  font-size: 20px;
}
.price_local {
  font-size: 14px;
}
ul.project_detail_ul {
  padding-left: 15px;
  line-height: 20px;
}
// .space_canvas{
//   width: 60% !important;
// }
.col-md-12.border-top.border-bottom.mt-3.pt-3.mb-3.pb-3:hover {
  background: #31a3d90d;
}
.card {
  border-radius: 15px;
}
.badges_project span {
  background: #d5d5d561;
  padding: 4px 13px;
  margin-right: 10px;
  margin-top: 14px !important;
  display: inline-block;
  border-radius: 50px;
  font-size: 12px;
  font-weight: 600;
  // color: #00000091;
  color: #000;
}

.badges_project span:hover {
  background: #d5d5d5bf;
}

.btn-outline-primary {
  color: #31a3d9;
  border-color: #31a3d9;
}
.right_assistent.align-items-center.d-flex span {
  // color: #8b8b8b;
  color: #000;
}
.profiledetailscls P {
  line-height: 20px;
}

a {
  cursor: pointer;
}

.taableWrapper {
  max-height: calc(100vh - 250px);
}

button.btn.btn-primary.loginclass {
  background: #20a2dc;
  border-radius: 44px;
  width: 100%;
  height: 49px;
  font-size: 14px;
  font-weight: 600;
  padding: 5px 15px;
}

@media (max-width: 768px) {



.d_flexbx .moddecsheader {
    font-size: 19px;
}

  .searcbtns{
    width: 100%;
  }
  .main_selects {
    width: 100%;
  }
  .main_selects div:first-child {
    width: 100%;
  }
  .searcbtns form{
    width: 100%;
  }


  .taableWrapper {
    max-height: calc(100vh - 300px);
  }
}

.login-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;

  > .container {
    max-width: 500px;

    .shadow {
      border-top: 3px solid $primaryColor;
    }

    .login-logo {
      width: 100%;
      max-width: 150px;
    }
  }
}

.bginput {
  border: 1px solid #e8e8e8 !important;
  // background: #F6F6F6 !important;
  border-radius: 8px !important;
}

.empty-box {
  padding: 15px;
  text-align: center;

  .icon-box {
    padding: 15px 30px;
    font-size: 26px;
    margin-bottom: 10px;
  }
}

h3.text-left.lgtext {
  font-weight: 600;
  font-size: 24px;
}

.paraclss {
  font-weight: 400;
  font-size: 20px;
  line-height: 29px;
  // color: #775DA6 !important;
  color: #000;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  // color: #F6F6F6;
  color: #000;
  opacity: 1;
  /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  // color: #F6F6F6;
  color: #000;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  // color: #F6F6F6;
  color: #000;
}

.modal {
  overflow-y: auto;

  .card-body,
  .modal-boldy {
    max-height: calc(100vh - 200px);
    overflow-y: auto;
  }
}

.upload-btn {
  position: relative;

  > input {
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }
}

.pagination {
  > li {
    padding: 2px;

    a {
      padding: 7px 10px;
      display: inline-block;
      border: 1px solid #e8e8e8;
      border-radius: 4px;
      font-size: 14px;
      min-width: 40px;
      text-align: center;
      color: #000;
      text-decoration: none;
    }

    &.active {
      a {
        background-color: $primaryColor;
        border-color: $primaryColor;
        color: #fff;
      }
    }

    &.disabled {
      a {
        border-color: #e8e8e8;
        color: #e8e8e8;
      }
    }
  }
}

.shine {
  background: #eee;
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  background-size: 200% 100%;
  animation: 1.5s shine linear infinite;
  min-height: 20px;
  border-radius: 5px;
}

.shine.shineCard {
  height: 250px;
}

strong.shine {
  display: block;
  margin-bottom: 14px;
}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}

.table td,
.table th {
  padding: 0.75rem;
  vertical-align: middle;
}

// edit icon css
i.fa.fa-pencil-alt.edit {
  margin: 0px 15px;
  font-size: 18px;
  color: #7561a2;
  font-weight: 700;
}

i.fa.fa-trash {
  color: #c02e2e;
}

.filterFlex {
  white-space: nowrap;
  column-gap: 10px;
  row-gap: 10px;
  flex-wrap: wrap;
}

.checkPrice {
  column-gap: 10px;
  span {
    display: inline-block;
    input {
      margin-right: 5px;
    }
  }
}
i.fa.fa-eye-slash.slashicon {
  position: absolute;
  right: 10px;
  top: 73% !important;
  transform: translateY(-50%);
  // color: #84818A;
  color: #000;
}

i.fa.fa-eye.eyeicon {
  position: absolute;
  right: 10px;
  top: 73% !important;
  transform: translateY(-50%);
  // color: #84818A;
  color: #000;
}

// Responsive Design
.buttons_Section {
  display: flex;
  align-items: center;
  margin-right: 12px;
}

.cardList {
  margin-top: 2rem;
}

a.btn.btn-primary.mr-2 {
  white-space: nowrap;
}

.form-control {
  // padding: 21px 10px;
  border: 1px solid #dcdcdc;
  border-radius: 0px;
  &.invalid {
    border-color: red !important;
  }
}

select.form-control.types {
  height: 43px;
  margin-right: 12px;
  width: 100%;
  max-width: 200px;
}
p.phone_no {
  // color: #84818A;
  color: #000;
}
.new_cards {
  background: #fff;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  max-height: 300px;
  // margin-bottom: 4.2rem;
}

input.searchbar.mr-2 {
  padding: 8px;
  border-color: #cdd4da;
  border-radius: 5px;
  outline: none;
  border: 1px solid #cdd4da;
  height: 43px;
}

// .d-flex.justify-content-between.align-items-center {
//   flex-wrap: wrap;
// }

// profile changes
input.searchbar.mr-2:focus {
  box-shadow: 0 3px 10px 0 17%;
  border-color: #7561a2;
}

a.btn.btn-primary.profiles {
  background-color: #fff !important;
  border: 1px solid #c2c2c2 !important;
  height: auto;
  color: #2020209c !important;
  border-radius: 12px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

i.material-icons.prob {
  font-size: 27px;
  margin: 0px 7px;
  color: #0000004a;
}
.btn:focus-visible {
  box-shadow: none !important;
}
.btn.btn-secondary.dropdown-toggle.heder_drop:focus {
  border: none !important;
  box-shadow: none !important;
}

label.btn.btn-primary.delete.ml-3 {
  background-color: #fff !important;
  border-radius: 10px;
  color: #4ba5d9 !important;
  font-size: 14px !important;
  font-weight: 500;
}

label.btn.btn-primary.edit.ml-3 {
  border-radius: 10px;
  font-weight: 600;
  font-size: 14px;
}

.btn.btn-secondary.discard {
  background-color: #fff !important;
  border: 1px solid #dcdcdc !important;
  color: #202020 !important;
  font-size: 14px !important;
  border-radius: 50px;
  font-weight: 500;
}

a.discard {
  text-decoration: none;
  color: #202020 !important;
  font-size: 14px;
  font-weight: 500;

  & :hover {
    color: #202020 !important;
    font-size: 14px;
    font-weight: 500;
  }
}
div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm {
  background-color: #31a3d9 !important;
}
div:where(.swal2-container) button:where(.swal2-styled).swal2-deny {
  background-color: #ea5c32 !important;
}
.btn.btn-primary.reset {
  background-color: #fff !important;
  border: 1px solid #dcdcdc !important;
  color: #202020 !important;
  font-size: 14px !important;
  border-radius: 50px !important;
}

button.btn.btn-primary.edit.ml-3 {
  border-radius: 50px !important;
  font-weight: 600;
  font-size: 14px;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-color-type: #202020 !important;
  --bs-table-bg-type: rgb(249 249 249 / 100%) !important;
}

a.btn.barlink.text-primary.active {
  outline: none;
  border: none;
}

a.btn.barlink.text-primary:active {
  border: none;
}

p.profile_data {
  margin-bottom: 0px !important;
  font-size: 14px;
  font-weight: 400;
  // color: #5A5A5A;
  color: #000 !important;
}

.col-md-12.inputFlex {
  line-height: 30px;
}

.highlightOption:not(:hover) {
  background: initial;
  color: initial;
}

// Responsive css:

@media only screen and (min-width: 1200px) and (max-width: 1400px) {
  .sales_section {
    padding: 20px;
  }

  .graph_section {
    padding: 20px;
  }

  .min-sidebar ~ div > .main-sidebar i {
    margin-right: 0px !important;
  }
}

@media only screen and (min-width: 1106px) and (max-width: 1199px) {
  .sales_section {
    padding: 20px;
  }

  .graph_section {
    padding: 20px;
  }

  .min-sidebar ~ div > .main-sidebar i {
    margin-right: 0px !important;
  }

  .shadow.bg-white.p-3.text-center.roundedBorder.repeat {
    margin-top: 15px;
  }
}

@media screen and (min-width: 1001px) and (max-width: 1105px) {
  .min-sidebar ~ div > .main-sidebar i {
    margin-right: 0px !important;
  }

  .dropdown.addDropdown.reseller_status {
    margin-top: 20px;
  }

  .shadow.bg-white.p-3.text-center.roundedBorder.repeat {
    margin-top: 15px;
  }
}
@media screen and (min-width: 973px) and (max-width: 992px) {
  .recentlyPostedWorksContainer {
    font-size: 32px !important;
  }
  .next_div,
  .chooseDifferentCategoryContainer2 {
    font-size: 32px !important;
  }
  .chooseDifferentCategoryContainer2 {
    font-size: 32px !important;
  }
}
@media only screen and (min-width: 991px) and (max-width: 1000px) {
  .works,
  .works_t {
    font-size: var(--font-size-37xl);
  }
  .recentlyPostedWorksContainer span {
    font-size: var(--font-size-37xl);
  }

  .clls {
    left: 18%;
  }
  .border {
    margin-top: 12px;
  }
  .shadow.bg-white.p-3.text-center.roundedBorder.repeat {
    margin-top: 15px;
  }

  .min-sidebar ~ div > .main-sidebar {
    display: none !important;
  }

  .main-navbar.min-sidebar {
    padding-left: 60px;
    display: flex;
  }

  .main-sidebar {
    position: absolute !important;
    top: 143px;
    z-index: 998;
  }

  .main-navbar {
    padding-left: 25px;
  }

  .sidebar-brand.p-3.pt-4.text-left.pl-5 {
    display: none;
  }

  .min-sidebar ~ div > main.main {
    width: 100% !important;
  }

  .d-flex.justify-content-between.align-items-end.mb-0 {
    display: block !important;
  }

  article.d-flex {
    margin-top: 1rem;
  }

  .main-wrapper main.main {
    width: 100% !important;
  }

  .d-flex.justify-content-between.align-items-end.mb-3 {
    display: block !important;
  }

  .selectDropdown::placeholder {
    width: 100px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  span.side_head {
    font-size: 14px;
  }

  .main-navbar {
    padding-left: 60px !important;
  }
}

// responsive css//

// @media(min-width:768px){
//   .main-navbar.min-sidebar ~ div > .main-sidebar{
//     display: block !important;
//   }
// }

@media screen and (min-width: 768px) and (max-width: 1000px) {
  .main-navbar.min-sidebar ~ div > .main-sidebar {
    display: none !important;
  }

  .shadow.bg-white.p-3.text-center.roundedBorder.repeat {
    margin-top: 15px;
  }
}

@media screen and (max-width: 991px) {
  .h_drop {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  li.nav-item.nav-link.headerChild.mr-0,
  .headerItem {
    padding-left: 1rem;
  }

  .phTop {
    margin-top: 10px;
  }
  .d-flex.align-items-center.Proposals_bottom {
    display: block !important;
    line-height: 2;
  }
  .border_total_sale {
    border: none;

  }
  


  .bg-white.pt-4 .container.pl-5.pr-5.mt-5 {
    margin-top: 0px !important;
    height: 100vh;
  }
  span.bg_bages {
    margin-bottom: 10px !important;
    display: inline-block;
    margin-right: 5px !important;
  }
  img.uploadimage {
    width: 100% !important;
    height: 100% !important;
    object-fit: cover;
  }
  .d-flex.align-items-center.Proposals_bottom p {
    margin: 0px !important;
  }
  .footer_btn {
    background: #fff;
    position: fixed;
    bottom: 0px;
    padding: 14px 2rem;
  }
  a.edit_job_post {
    width: fit-content;
    float: right;
  }
  a.profiles_edit {
    bottom: 0px !important;
  }
  button.btn.btn-primary.w-25.mb-3.ml-3.mr-0 {
    width: 50% !important;
  }
  .rate_from input {
    width: 90px;
    text-align: right;
  }
 
  .col-md-9.pr-5.mt-3 {
    padding-right: 15px !important;
  }
  .col-md-9.head_cls {
    margin-bottom: 20px;
  }

  .shadow.bg-white.p-3.text-center.roundedBorder.repeat {
    margin-top: 15px;
  }
  .flex-xl-col {
    flex-direction: column;
    align-items: flex-start !important;
}
}

@media only screen and (min-width: 602px) and (max-width: 990px) {
  .shadow.bg-white.p-3.text-center.roundedBorder.repeat {
    margin-top: 15px;
  }

  .main-navbar {
    padding-left: 60px !important;
  }

  .min-sidebar ~ div > .main-sidebar {
    display: none !important;
  }

  .main-navbar.min-sidebar {
    // padding-left: 60px;
    display: flex;
  }

  .main-sidebar {
    position: absolute !important;
    top: 144px;
    z-index: 998;
    max-height: 770px;
    height: 100%;
    overflow: auto;
  }

  .main-navbar {
    padding-left: 60px;
  }

  .sidebar-brand.p-3.pt-4.text-left.pl-5 {
    display: none;
  }

  .min-sidebar ~ div > main.main {
    width: 100% !important;
  }

  .d-flex.justify-content-between.align-items-end.mb-0 {
    display: block !important;
  }

  article.d-flex {
    margin-top: 1rem;
  }

  .main-wrapper main.main {
    width: 100% !important;
  }

  .d-flex.justify-content-between.align-items-end.mb-3 {
    display: block !important;
  }

  .selectDropdown::placeholder {
    width: 100px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .selectDD {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .col-12.col-sm-12.col-md-12.col-lg-6.cls {
    margin-top: 15px;
  }

  // span {
  //   font-size: 14px;
  // }

  span.side_head {
    font-size: 14px !important;
  }

  .card.adds {
    margin-top: 20px;
  }
}

@media screen and (min-width: 691px) and (max-width: 854px) {
  .shadow.bg-white.p-3.text-center.roundedBorder.repeat {
    margin-top: 15px;
  }

  .dropdown.addDropdown.reseller_name {
    margin-top: 0px !important;
  }

  .dropdown.addDropdown.reseller_status {
    margin-top: 20px;
  }
}

@media screen and (max-width: 690px) {
  .dropdown.addDropdown.reseller_name {
    margin-top: 20px !important;
  }

  .dropdown.addDropdown.reseller_status {
    margin-top: 20px;
  }
}

@media only screen and (min-width: 528px) and (max-width: 600px) {
  .shadow.bg-white.p-3.text-center.roundedBorder.repeat {
    margin-top: 15px;
  }

  .main-navbar {
    padding-left: 60px !important;
  }

  .min-sidebar ~ div > .main-sidebar {
    display: none !important;
  }

  .main-navbar.min-sidebar {
    padding-left: 60px;
    display: flex;
  }

  .main-navbar {
    padding-left: 60px !important;
  }

  .sidebar-brand.p-3.pt-4.text-left.pl-5 {
    display: none;
  }

  .min-sidebar ~ div > main.main {
    width: 100% !important;
  }

  // proffile dropdown
  // .dropdown.ml-auto {
  //   top: -40px;
  // }

  // .headerSearch {
  //   top: 10px !important;
  // }

  // category css
  .d-flex.justify-content-between.align-items-end.mb-0 {
    display: block !important;
  }

  article.d-flex {
    margin-top: 1rem;
  }


  p.mb-0 {
    font-size: 13px;
  }

  img.listIc {
    height: 40px;
    width: 40px;
  }

  form.headerSearch.ml-3 {
    width: auto;
    max-width: 130px;
  }

  // .ml-3 {
  //   width: 300px;
  // }

  span.side_head {
    font-size: 14px;
  }

  article.d-flex {
    margin-top: 1rem;
    flex-wrap: wrap;
  }

  button#dropdownMenuButtonstatusDropdown\ reset {
    margin-top: 15px;
  }

  // new css 19-07-2023//
  .dropdown.addDropdown.reseller_name {
    margin-top: 20px !important;
  }

  .dropdown.addDropdown.reseller_status {
    margin-top: 20px;
  }
}

@media screen and (max-width: 590px) {
  button.btn.btn-primary.btnreset {
    margin-top: 15px !important;
  }

  a.btn.btn-primary.btnreset {
    margin-top: 15px !important;
  }

  .shadow.bg-white.p-3.text-center.roundedBorder.repeat {
    margin-top: 15px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 527px) {
  .shadow.bg-white.p-3.text-center.roundedBorder.repeat {
    margin-top: 15px;
  }

  .main-navbar {
    padding-left: 60px !important;
  }

  .min-sidebar ~ div > .main-sidebar {
    display: none !important;
  }

  form.pprofile1.form-row.py-3.w-50.mx-auto {
    width: 100% !important;
  }

  .text-center.d-flex.justify-content-between.mt-5 {
    display: flex !important;
  }

  // form.centerLogin div {
  //   margin-bottom: 10px !important;
  //   margin-top: 10px !important;
  // }

  p.accopunt {
    text-align: right;
    right: 20px;
    padding: 0rem 0rem !important;
    font-size: 16px;
    font-weight: 400;
    top: 8px;
  }

  p.accopunts {
    position: relative !important;
  }

  .google_id {
    width: 48%;
  }

  a.btn.barlink.text-primary {
    left: 19px;
    width: 35px;
    height: 35px;
    padding: 7px 9px;
    top: 17px;
  }

  .main-sidebar {
    position: absolute !important;
    top: 143px;
    z-index: 998;
  }

  .main-navbar.min-sidebar {
    padding-left: 40px;
    display: flex;
  }

  .main-navbar {
    padding-left: 40px !important;
  }

  .headerSearch .Searchbar {
    width: 100%;
    max-width: 130px;
  }

  .sidebar-brand.p-3.pt-4.text-left.pl-5 {
    display: none !important;
  }

  .min-sidebar ~ div > main.main {
    width: 100% !important;
  }

  // proffile dropdown
  .dropdown.ml-auto {
    // top: -30px;
    // right: -16px;
  }

  .headerSearch {
    top: 20px !important;
  }

  // main p-age

  .d-flex.justify-content-between.align-items-end.mb-3 {
    display: block !important;
  }

  .nav-tabs {
    border-radius: 3px !important;
    height: 40px !important;
    background: none !important;
    border: none !important;
    margin-top: 1rem;
  }

  .icons_tab {
    margin: 0px !important;
  }

  .nav-tabs {
    border-bottom: 1px solid #ffffff;
  }

  article.d-flex {
    display: block !important;
    margin-top: 1rem !important;
  }

  .new_cards {
    height: 100% !important;
    margin-bottom: 0rem !important;
    max-height: 500px !important;
    //  height: 100%;
    //   margin-bottom: 0rem !important;
  }

  li.list_names {
    text-align: left !important;
  }

  .user_proff {
    display: block !important;
  }

  // category css
  .d-flex.justify-content-between.align-items-end.mb-0 {
    display: block !important;
  }

  select.form-control.types {
    margin-top: 13px;
    width: 100%;
  }





  button.btn.btn-primary.btnreset {
    margin-top: 15px;
  }

  // dashboard

  h2.mb-1.mainHeading_cls {
    font-size: 20px;
    font-weight: 600;
  }

  p.grayCls.mb-0 {
    font-size: 12px;
    font-weight: 200;
    // color: #898989;
    color: #000;
    width: auto !important;
  }

  a.btn.barlink.text-primary {
    // left: 8px;
    // width: 35px;
    // height: 35px;
    // padding: 7px 9px;
    left: 19px !important;
    width: 35px !important;
    height: 35px !important;
    padding: 7px 9px !important;
    top: 17px !important;
  }

  form.headerSearch.ml-3 {
    width: 100% !important;
    margin-left: 9px !important;
  }

  // p.mb-0 {
  //   white-space: nowrap;
  //   width: 180px;
  //   overflow: hidden;
  //   text-overflow: ellipsis;
  // }

  // .ml-3 {
  //   width: 200px;
  // }
  img.listIc {
    height: 40px;
    width: 40px;
  }

  .borderBox.d-flex.align-items-center.justify-content-between {
    padding: 0px 15px;
  }

  .timeBoxs p {
    font-size: 14px;
    // color: #484848;
    color: #000;
    line-height: 17px;
  }

  .headerSearch .Searchbar {
    width: 100%;
    max-width: 130px;
  }

  // form.headerSearch.ml-3 {
  //   width: 130px;
  // }

  // dashboard responsive
  .graph_range {
    display: block;
  }

  p.view_graph {
    margin-top: 18px;
  }

  // admin user
  // .d-flex.justify-content-between.align-items-center {
  //   display: block !important;
  // }

  span.side_head {
    font-size: 14px;
  }

  .dropdown.addDropdown {
    margin-top: 15px;
  }

  // new css 19-07-2023
  .hedding {
    margin-bottom: 15px !important;
  }

  h2.mb-5 {
    font-size: 18px !important;
    margin-bottom: 20px !important;
  }

  .dropdown.addDropdown.booking_system.mr-2.w-100 {
    margin-top: 0px !important;
  }
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .hedding {
    margin-bottom: 20px !important;
  }

  .text-center.tableHeading h2 {
    font-size: 23px !important;
  }

  .sidebar-brand.p-3.pt-4.text-left.pl-5 {
    display: none !important;
  }

  .min-sidebar ~ div > .main-sidebar a.col_side:hover {
    justify-content: center;
    margin: 8px 14px !important;
  }

  .min-sidebar ~ div > .main-sidebar {
    display: block !important;
    max-width: 250px;
    min-width: 250px !important;
    // min-height: 100vh;
    height: 100%;
    margin-top: -72px;
    border-right: 1px solid #e8e8e8;
    position: absolute;
    z-index: 1;
    top: 145px;
    max-height: 770px;
    overflow: auto;
  }

  .sidees {
    display: flex;
  }

  .min-sidebar ~ div > .main-sidebar a.nav-link.hoverclass {
    padding: 9px 16px;
    border-radius: 12px !important;
    margin-right: 10px !important;
    display: flex !important;
    justify-content: left !important;
    width: auto !important;
  }

  .min-sidebar ~ div > .main-sidebar a.col_side {
    padding: 9px 16px;
    border-radius: 12px !important;
    margin-right: 10px !important;
    display: flex !important;
    justify-content: space-between !important;
    width: auto !important;
  }

  .main-navbar {
    padding-left: 50px !important;
  }

  .btn.btn-secondary.discard {
    background-color: #fff !important;
    border: 1px solid #dcdcdc !important;
    color: #202020 !important;
    font-size: 14px !important;
    border-radius: 50px;
    font-weight: 500;
  }

  .min-sidebar ~ div > .main-sidebar .nav-link span {
    display: block !important;
  }

  .min-sidebar ~ div > .main-sidebar a.col_side span {
    display: flex !important;
    font-size: 14px !important;
    font-weight: 300 !important;
    color: #202020;
  }

  .min-sidebar ~ div > .main-sidebar i {
    margin-right: 8px !important;
  }

  .main-sidebar {
    display: none !important;
  }

  .main-sidebar .nav .nav-item .nav-link i.sidenv {
    display: none !important;
  }

  .card.card-body.sides li a {
    border-radius: 4px !important;
    padding-left: 42px !important;
  }
}

@media only screen and (min-width: 320px) and (max-width: 500px) {
  .main-navbar {
    padding-left: 25px !important;
  }

  a.btn.barlink.text-primary {
    left: 3px !important;
    width: 35px !important;
    height: 35px !important;
    padding: 7px 9px !important;
    top: 20px !important;
  }

  button:focus:not(:focus-visible) {
    outline: 0;
    box-shadow: none !important;
  }
}

.disabledbutton {
  cursor: not-allowed !important;
}

.messageUnread {
  font-size: 12px;
  position: absolute;
  margin-top: -9px;
  top: 0;
  right: 0;
  margin-left: -3px;
  background-color: #b5f4fe;
  border-radius: 61%;
  width: 21px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 21px;
  padding: 2px !important;
  text-align: center;
  /* box-shadow: 1px 1px #745f5f; */
  /* color: white; */
  animation-name: Myani;
  /* animation-duration: 2s; */
  /* animation-iteration-count: infinite; */
}

// @keyframes Myani{
//   0%{opacity: 1;}
//   50%{opacity: 0.5;}
//   100%{opacity: 0.2;}
// }

.dashboardbox {
  width: 200px;
  height: 100px;
  border: 1px solid black;
  padding: 10px;
  margin: 10px;
}

.disabled {
  cursor: not-allowed !important;
}

.floating-chat-icon {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #2c2d2f;
  color: #fff;
  padding: 10px;
  border-radius: 31%;
  cursor: pointer;
  font-size: 29px;
}

//  offer details tracker
.tracker_div input#flexSwitchCheckDefault {
  margin-left: 0 !important;
}
.tracker_div input#flexSwitchCheckDefault {
  width: 37px;
  height: 20px;
  margin: 0;
}


.tracker_div input#flexSwitchCheckDefault:focus{
  box-shadow: unset;
}
// .tracker_div {
//   margin-top: 130px;
// }
.clock-width .form-check-input {
  position: unset;
}


// Notes table css
.th_w_limit_cont {
  width: 280px;
}
.th_w_limit {
  width: 320px;
}
.not_tab_elipsis {
  max-width: 280px;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
}
.tox .tox-sidebar-wrap {
  border: 1px solid #dcdcdc;
}
// .tox.tox-tinymce {
//   max-height: 285px !important;
// }

// Notice view model css
.view_pop_input {
  border-radius: 6px !important;
  padding: 5px;
  border: 1px solid #dcdcdc;
}
.view_textarea {
  width: 100%;
  height: 200px;
  border-radius: 6px !important;
  padding: 5px;
  border: 1px solid #dcdcdc;
}
.mod_view_h5 {
  font-size: 15px;
}
.table_new {
  border-collapse: inherit;
}

.roundimage {
  width: 40px;
  aspect-ratio: auto 50 / 50;
  height: 40px;
  border-radius: 50%;
}

.border-grey {
  border: 2px solid #dddddd !important;
}
.roundbox {
  border-radius: 75px !important;
}

// Offer modal css
.offers_pop_main {
  display: flex;
}

.offers_pop_modal {
  padding: 70px;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  margin: auto;
  border-radius: 30px;
  padding-top: 30px;
}
.waiting_text_modal {
  font-size: 28px;
}
.waiting_desc_modal {
  color: #626060;
  font-size: 15px;
}
.waiting-image {
  height: 170px;
}

// add bank account page css
.bank_account_card {
  border: 1px solid #dedede;
  border-radius: 20px;
  padding: 20px 30px;
}
.list_group_div {
  width: 60%;
  margin: auto;
}
.add_account_width {
  margin: auto;
}
.width_adjust {
  margin: auto;
}
.list-group-item {
  padding: 1.25rem 1.25rem;
}
.not_found_img {
  max-width: 35%;
}
.bank_numb {
  background: #80808066;
  padding: 5px;
  border-radius: 5px;
}
.banks_list {
  max-height: 800px;
  overflow: auto;
}
@media (min-width: 992px) {
  .add_account_width {
    width: 75%;
  }
  .width_adjust {
    width: 50%;
  }
  .homes_row .col-md-4 {
    padding-right: 35px !important;
    padding-left: 35px !important;
  }
}
.working_tr th {
  background-color: #80808094;
}
.wk {
  font-size: 12px;
}
.card_due_input {
  max-width: 150px;
}
.cards_heaight {
  max-height: 300px;
  overflow-y: scroll;
}

// About page css
.abouthead {
  background-image: url("../../public/assets/img/aboutheader.png");
  padding: 22px 0px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.about_back {
  color: #fff;
  background: none;
  border: none;
  padding-left: 35px;
}
.about_span {
  color: #fff;
  font-weight: 500;
  font-size: 26px;
}

img.about1_img {
  width: 100%;
  height: 500px;
}

.about_heading {
  color: var(--color-deepskyblue-100);
  font-size: 24px;
  font-weight: 600;
  line-height: 30px;
  margin-top: 40px;
}
.about_bar {
  background-color: var(--color-deepskyblue-100);
}
.about_bar {
  background-color: var(--color-deepskyblue-100);
  width: 75px;
  height: 3px;
}
.about_desc {
  margin-top: 15px;
  font-size: 300;
  font-weight: 300;
  color: #000;
  line-height: 28px;
  font-size: 15px;
}
.about_hero_row {
  margin-top: 210px;
}
.about_hero_child {
  max-width: 560px;
  padding: 20px 37px;
  border: 10px solid;
}
.about_desc.about_desctwo {
  line-height: 25px;
}
.abouthero_parent {
  background-image: url("../../public/assets/img/abouthero.png");
  height: 475px;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
}
.about_hero_child {
  max-width: 560px;
  padding: 20px 37px;
  border: 10px solid var(--color-deepskyblue-100);
  position: relative;
  margin-left: auto;
  left: -35px;
  background-color: #fff;
  top: -115px;
}
.abouth_two {
  line-height: 42px;
}

.hero_2 {
  background-image: url("../../public/assets/img/hero2.png");
  height: 400px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.hero2_child {
  color: #fff;
}
.hero2_header {
  font-size: 12px;
}
.hero2_desc {
  font-weight: 600;
  font-size: 40px;
}
::-webkit-scrollbar {
  width: 5px !important;
  height: 5px !important;
}
@media (max-width: 992px) {
  .wk {
    width: fit-content;
  }
}
@media (max-width: 767px) {
  .about1_img {
    width: auto !important;
    height: 365px !important;
  }
  .about_heading {
    font-size: 20px;
    line-height: 28px;
  }
  .about_desc {
    line-height: 25px;
    font-size: 12px;
  }
  .btn.btn-primary.about_btn.Subscribe {
    margin-top: 0px;
  }
  .about_desc.about_desctwo {
    line-height: 27px;
  }
  .about_hero_child {
    max-width: 385px;
  }
  .abouth_two {
    line-height: 34px;
  }
  .cmc_table label {
    font-size: 12px;
  }
  .cmc_table .col-3 {
    padding: 6px !important;
    font-size: 12px;
  }
}
@media (min-width: 767px) {
  .row.about_row_two {
    margin-top: 130px;
  }
}
@media (max-width: 488px) {
  .abouthead {
    flex-wrap: wrap;
    white-space: nowrap;
  }
}
@media (max-width: 480px) {
  .about_span {
    font-size: 16px;
  }
  .about_back {
    font-size: 10px;
  }
  .about1_img {
    width: auto !important;
    height: auto !important;
  }
  .about_heading {
    font-size: 16px;
    line-height: 25px;
  }
  .about_desc {
    line-height: 22px;
    font-size: 12px;
  }
  .btn.btn-primary.about_btn.Subscribe {
    width: 135px;
    height: 35px;
    font-weight: 600;
    font-size: 12px;
  }
  .about_hero_child {
    left: auto;
  }
  .hero2_header {
    font-size: 8px;
  }
  .hero2_desc {
    font-weight: 600;
    font-size: 18px;
  }
  .lgtext {
    font-size: 18px;
  }
  .prilab {
    font-size: 10px;
  }
  .google_heading {
    font-size: 13px;
  }
  a.btn.btn-outline.google_id {
    margin-top: 12px;
  }
  .Jobs_Jobs {
    font-size: 16px;
  }
  .offcanvas-body h3 {
    font-size: 16px;
  }
  .contractsheader {
    font-size: 24px;
    margin-bottom: 14px !important;
  }
 
  .contract_box h5 {
    font-size: 16px;
    margin-bottom: 10px;
  }
  h3.creatheader {
    font-size: 20px;
  }
  .right_side {
    padding: 16px !important;
  }

  // ::-webkit-scrollbar {

  //   width: 5px !important;
  //   height: 5px !important;
  //   }
  .wk {
    width: fit-content;
  }
  .cmc_table label {
    font-size: 10px;
  }
  .cmc_table .col-3 {
    padding: 3px !important;
    font-size: 10px;
  }
}

// Chat css
.chat__wrapper {
 height: 500px;
  border: 1px solid var(--border-color);
  border-radius: 15px;
}
.chat__content {
  height: 500px;
}
.chat__list-messages li .chat__bubble {
  margin-bottom: 0.5rem;
}
select option {
  white-space: pre-wrap !important;
}

.select_optionlimit select option {
  line-break: anywhere;
}
.chat-member__avatar img {
  border-radius: 50%;
  width: 50px !important;
  height: 50px !important;
  object-fit: cover !important;
}
// employer css

.employer_filt {
  background: none !important;
  color: rgba(0, 0, 0, 0.88) !important;
  border: 1px solid #d9d9d9 !important;
  padding: 10px 15px !important;
  min-width: 175px;
  border-radius: 10px;
}

.employer_filt.dropdown-toggle::after {
  display: none !important;
}
.employer_filt:hover {
  border-color: #00a5d9 !important;
}
:where(.css-dev-only-do-not-override-xu9wm8).ant-picker-focused.ant-picker {
  border-color: #00a5d9 !important;
}
:where(.css-dev-only-do-not-override-xu9wm8).ant-picker-range
  .ant-picker-active-bar {
  background: #00a5d9 !important;
}
.cmc_table {
  max-height: 500px;
  overflow: auto;
  overflow-x: hidden;
}
// .cms {
//   border-bottom: 1px solid #80808052;
// }
.pms {
  margin-bottom: 0;
  padding: 15px 0px;
}
.npms {
  color: #00a5d9 !important;
  font-weight: 500;
}
.gms {
  color: #000000de;
}

// Referal page css
.referal_main {
  background-image: url("../../public/assets/img/refral.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 500px;
}
.referal_heading {
  font-weight: 600;
  color: #fff;
  font-size: 45px;
  max-width: 555px;
  line-height: 38px;
  line-height: 60px;
}
.refral_desc {
  margin-top: 20px;
  color: #fff;
  font-weight: 600;
  font-size: 18px;
}
.referal_btn {
  margin-top: 15px;
  width: 230px;
  height: 53px;
  border-radius: 10px;
}
.ref_pro_container {
  margin-top: 100px;
}
.css-lpahdxg-control{
  border-radius: 50px !important;
  box-shadow: 0px !important;
}
.ref_pro {
  color: #000000;
  font-weight: 500;
  font-size: 25px;
}
.ref_pro_desc {
  color: #000000;
  margin-top: 30px;
  font-weight: 400;
  font-size: 15px;
  line-height: 25px;
}

@media (max-width: 767px) {
  .referal_heading {
    font-size: 33px;
    line-height: 38px;
  }
  .refral_desc {
    font-size: 14px;
  }
  .referal_btn {
    margin-top: 15px;
    width: 190px;
    height: 42px;
  }
  .ref_pro_desc {
    margin-top: 20px;
    font-size: 12px;
    line-height: 20px;
  }
}
@media (max-width: 480px) {
  .referal_main {
    height: 285px;
  }
  .referal_heading {
    font-size: 20px;
    line-height: 25px;
  }
  .refral_desc {
    margin-top: 12px;
    font-weight: 400;
    font-size: 10px;
  }
  .referal_btn {
    margin-top: 6px;
    width: 145px;
    height: 32px;
    border-radius: 5px;
    font-size: 12px;
  }
  .ref_pro_container {
    margin-top: 40px;
  }
  .ref_pro {
    font-size: 18px;
  }
  .ref_pro_desc {
    color: #000000;
    margin-top: 10px;
    font-weight: 400;
    font-size: 10px;
    line-height: 18px;
  }
  .newh4 {
    font-size: 25px !important;
  }
  .newgrow {
    font-size: 16px !important;
  }
  .missionp {
    font-size: 13px !important;
    line-height: 26px !important;
    margin-bottom: 20px !important;
  }
  .missohead {
    font-size: 25px !important;
  }
  .missionheader {
    width: 100% !important;
    font-size: 16px !important;
  }
  .missiondesc {
    font-size: 14px !important;
  }
  .missiondiv {
    margin-bottom: 25px !important;
  }
}

// Contact us page
.contact_card {
  border: 1px solid #80808054;
  padding: 30px 20px 40px 20px;
  border-radius: 10px;
}
textarea.form-control.area_contact {
  min-height: 135px;
}

.slick-initialized .slick-slide {
  display: block;
  padding: 0 15px 0 0;
}
.h_drop {
  display: flex;
}
.cursor-pointer {
  cursor: pointer;
}
span.call-action {
  font-weight: 500;
}

.cross {
  padding: 10px;
  cursor: pointer;
}

.cross i {
  margin-top: -5px;
  cursor: pointer;
}
// .responsive-map{
//   overflow :hidden;
//   height: 0;
//   padding-bottom: 30%;
//   position: relative;
// }

// .responsive-map iframe{
//   border: 0;
//   height:100%;
//   width: 100%;
//   left: 0;
//   top: 0;
//   position: absolute;
// }

.comment-box {
  padding: 5px;
}

// .comment-area textarea {
//     resize: none;
//     border: 1px solid #ff0000
// }

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #ffffff;
  outline: 0;
  // box-shadow: 0 0 0 1px rgb(255, 0, 0) !important
}

.rating {
  display: inline-flex;
  margin-top: -10px;
  flex-direction: row-reverse;
}

.rating > input {
  display: none;
}

.rating > label {
  position: relative;
  width: 28px;
  font-size: 35px;
  // color: #ff0000;
  cursor: pointer;
}

.rating > label::before {
  content: "\2605";
  position: absolute;
  opacity: 0;
  color: #ffc107 !important;
}

.rating > label:hover:before,
.rating > label:hover ~ label:before {
  opacity: 1 !important;
}

.rating > input:checked ~ label:before {
  opacity: 1;
}

.rating:hover > input:checked ~ label:before {
  opacity: 0.4;
}

.rev_head {
  font-size: 20px;
  font-weight: 800;
}

.rev {
  color: #000;
  font-size: 18px;
}

.rev_stars .fa-star {
  margin: 0px 1px;
}
.cmc_table {
  // border-bottom: 1px solid #80808029;
  padding: 0px 15px;
  margin-bottom: 20px;
}
.cmc_table .col-3 {
  // border: 1px solid #80808029;
  border-bottom: 0;
  // padding: 15px 15px;
  // text-align: center;
}
.cmc_table .firstcms {
  position: sticky;
  top: 0;
  border-bottom: 1px solid #dee2e6;
  z-index: 9;
  background: #f2f2f2;
  // padding: 10px 0px;
}
.table-text label {
  margin-top: 12px !important;
  margin-bottom: 12px !important;
}
.border-color {
  border-right: 1px solid #dee2e6 !important;
}
.cmc_table label {
  font-weight: 600;
  color: #000000;
  text-align: center;
  margin: 0;
}
.backit {
  background: pink;
  margin-bottom: 10px !important;
}
.backitagin {
  background: skyblue;
  margin-bottom: 10px !important;
}
.missionheader {
  font-size: 22px;
  font-weight: 500;
}
.missiondesc {
  font-size: 16px;
  color: #000;
  text-align: justify;
}
.missiondiv {
  margin-bottom: 70px;
}

.misimg {
  width: 335px;
  object-fit: contain;
  // height: 200px;
}
// .missohead{
//   text-decoration: underline;
// }
.missohead {
  color: #3aa0dc;
}
.missionheader {
  color: #ea5c32;
}
@media (max-width: 767px) {
  .rowreverse {
    flex-direction: column-reverse;
  }
}
.fonttext textarea {
  font-size: 17px !important;
}
.headarrow .fa {
  font-size: 25px;
}
.missionp {
  font-size: 16px;
  font-weight: 500;
  line-height: 30px;
  margin-bottom: 30px;
  text-align: justify;
}
.instap {
  font-style: normal !important;
  color: #aeb7c2;
}
.ratethis {
  font-size: 13px !important;
}
.whitespace {
  white-space: nowrap;
}
.colorthis {
  color: #ea5c32;
  font-weight: 600;
  font-size: 25px;
}
.normalpera {
  font-size: 16px;
  line-height: 28px;
}
.newh4 {
  font-size: 35px;
  color: #3aa0dc;
}
.bluecolor {
  color: #3aa0dc;
  cursor: pointer !important;
}
.redcolor {
  color: #ea5c32;
}
.newgrow {
  font-size: 20px;
}
.whiteskill .searchWrapper {
  background: #fff;
  display: flex;
}
.skillsbadge .bg_bages {
  margin: 3px;
  display: inline-block;
}
.anchorbtn {
  color: #fff !important;
  background-color: #00a5d9 !important;
  border-color: #00a5d9 !important;
}
.anchorbtn:hover {
  color: #fff !important;
  background-color: #00a5d9 !important;
  border-color: #00a5d9 !important;
}
.despcard {
  font-size: 12px;
  line-height: 20px;
}
.asbt {
  text-transform: none !important;
  background: none !important;
  color: #3ea3d9 !important;
  border-color: #3ea3d9 !important;
}
.asbt:hover {
  text-transform: none !important;
  color: #3ea3d9 !important;
  border-color: #3ea3d9 !important;
}
.asbt:active {
  text-transform: none !important;
  background: none !important;
  color: #3ea3d9 !important;
  border-color: #3ea3d9;
}
.asbt:focus {
  text-transform: none !important;
  background: none;
  color: #3ea3d9 !important;
  border-color: #3ea3d9 !important;
}
.asbtshow {
  margin-top: 2px !important;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px !important;
}
.asbtshow .dropdown-item:hover {
  background: #3ea3d9 !important;
  color: #fff !important;
}
.newback {
  font-size: 24px;
  background: #00a9d6;
  padding: 7px 14px;
  border-radius: 43px;
  color: #fff;
}
.gapmob {
  gap: 5px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.maindiv_boder.sc {
  overflow-x: auto;
}
.flow_flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.upload-new-file {
  position: relative;
  margin: 10px;
}
.upload-new-file i {
  font-size: 8px;
  position: absolute;
  right: 0;
}
.image-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 480px) {
  .contact_card {
    padding: 20px 10px;
  }
  .misimg {
    width: 265px;
  }
}
.modalheader {
  font-size: 25px !important;
  margin-bottom: 30px !important;
}

// New CSS
.checkbox {
  width: auto;
}
input::placeholder {
  color: #00000069 !important;
  font-size: 13px !important;
}
.image-shape img {
  object-fit: cover;
}
.enterYourMail input::placeholder {
  color: #fff !important;
}
.testimonial3 .text {
  height: 100% !important;
}
.testimonial3 img.avatarIcon {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border: 1px solid #eee;
}
// .testimonial3 .loremIpsumIs {
//   max-height: 100px;
//   overflow: auto;
// }
.banner-img {
  object-fit: contain;
  max-width: 600px;
  margin: 0 auto;
}
.footer-top {
  padding: 30px 0 60px 0;
}
.flex-col {
  display: flex;
  flex-direction: column;
}
.recentlyPosted {
  margin: 60px 0 0 0;
}
.profile-page .right_profile {
  background: #f2f3f459;
}
:where(.css-dev-only-do-not-override-xu9wm8).ant-select .ant-select-arrow {
  margin-top: -4px !important;
}
:where(.css-dev-only-do-not-override-xu9wm8).ant-select-single:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  height: 38px;
  border-radius: 50px;
}
.modal-content .ViewUser {
  font-size: 25px;
}
.edu-modal .modal-title {
  font-size: 20px;
}
.edu-modal .profileheddingcls {
  text-transform: capitalize;
}
.skill-set .css-13cymwt-control {
  border-radius: 10px !important;
}
.skills-modal .badges_cls {
  font-size: 12px;
}
.change-pw-inner {
  border: 1px solid #00000026;
  padding: 20px;
  border-radius: 23px;
  margin: 40px 0;
}
.pagination-custom .pagination {
  display: flex;
  justify-content: flex-end;
}
.stick-position .sticky {
  position: sticky;
  top: 30px;
}
.input-box input[type="checkbox"] {
  width: 24px;
}
.Sales_row.card h4 {
  margin: 0 0 17px 0;
  font-size: 16px;
  line-height: 23px;
}
.switch-btn {
  padding-left: 0 !important;
} 
.switch-btn label {
  padding-left: 45px !important;
}
.notes-img img {
  width: 100px;
  margin: 20px auto;
  display: block;
}
.shadow_bx form {
  border: 1px solid #d8eef8;
  padding: 20px;
  border-radius: 5px;
  margin: 20px 0;
  background: #d8eef852;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px;
}
td.border-bottom-main {
  border-bottom: 1px solid #dee2e6 !important;
}
.new-Tbl th {
  border: 0 !important;
  border-bottom: 1px solid #eee !important;
}
.new-Tbl thead{
  border-bottom: 1px solid #eee !important;
}
.new-Tbl .table td{
  border: 0 !important;
}
.text-truncate p{
  margin: 0 !important;
}
.new-Tbl i.fa.fa-eye {
  color: #00a4d6 !important;
}
.front-doc i {
  position: absolute;
  right: -10px;
  top: -7px;
  background: #fff;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 6px;
  border-radius: 50%;
  font-size: 12px;
}
.front-doc {
  position: relative;
  width: 100px;
  height: 100px;
}
.front-doc img{
  object-fit: cover;
}
.chat__list-messages li .chat__bubble img {
  object-fit: contain;
}
.bg_color {
  background: #f9f9f9;
  padding: 20px;
  border-radius: 24px;
  margin: 60px 0  !important;
}
.signup-modal .form-check img {
  width: 70px;
  margin: 0 auto 15px auto;
  height: 70px;
  display: block;
}
.signup-modal .form-check {
  border: 1px solid #00000014;
  padding: 26px;
  width: 32%;
  border-radius: 5px;
  background: #f9f9f9ef;
}
.signup-modal .form-check .form-check-input {
  position: absolute;
  right: 7px;
  top: 0;
}
.signup-modal .form-check img {
  width: 100px;
  margin: 0 auto 15px auto;
}
.signup-modal .form-check-label {
  font-size: 14px !important;
  text-align: center;
}
.login-btn {
  background: #20A2DC;
  color: #fff;
}
.signup-btn button {
  background: #D9D9D9;
  margin: 0 0 0 10px;
  color: #414141;
}
.edit-profile-page .profileImage {
  width: 100px !important;
  height: 100px !important;
  border-radius: 50%;
  object-fit: cover;
}
.mobile-show{
  display: flex !important;
}
.notify-none {
  display: block;
}
.second_flex span {
  width: 24%;
  margin: 10px 0;
}
.p-img img {
  object-fit: cover;
}
.total_data p {
  font-size: 12px;
  margin-bottom: 0;
}
.fw-600 {
  font-weight: 600;
}
.cancel-video button span {
  background: #fff;
  padding: 0px;
  width: 24px;
  height: 24px;
  display: block;
  border-radius: 50px;
  opacity: 1;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  z-index: 999;
  position: relative;
  border: 1px solid #00000052;
  right: -9px;
}
.fx-normal {
  font-size: 12px;
}
.policies-page th, .policies-page td {
  border-bottom: 1px solid #eee !important;
}
.policies-page th{
  background-color: #f2f3f4;
}
.policies-page table.table {
  border-left: 1px solid #eee;
  border-right: 1px solid #eee;
}
p.skills-set {
  border: 1px solid #e5e5e5;
  padding: 10px;
  border-radius: 50px;
  background-color: #c4e1f16e;
  min-height: 38px;
}

.all_skil .skill-set{
  min-height: 38px;
}
.skills-set .multiSelectContainer{

  border-radius: 50px;
  background-color: #c4e1f16e;
}
.new-Tbl {
  height: 400px;
  border: 1px solid #eee;
}
.staff-modal img {
  width: 100%;
  max-width: 100px;
  margin: 0 auto 20px auto;
  display: block;
}
.job-posts-sect {
  background: #fff;
}
.congo-modal img {
  width: 100px;
}
.skills-set span.gray_badges {
  font-size: 12px;
  margin: 4px 5px 4px 0 !important;
}
.main-title {
  font-size: 16px;
  margin: 0 0 13px 0;
  width: fit-content;
  color: #000000c4;
  // text-decoration: underline;
}
.badgey-grey {
  color: #000000db !important;
  margin: 4px 5px 4px 0 !important;
}
.dropdown-absolute {
  position: absolute;
  top: 9px;
  right: 1px;
}
.parah-ellipses p, .parah-ellipses h2, .parah-ellipses h3, .parah-ellipses strong {
  margin: 0;
  font-size: 13px !important;
  font-weight: 400 !important;
}
.parah-ellipses {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-size: 13px !important;
}
.job-p-new {
  width: 30%;
}
.despcard.parah-ellipses{
  width: 66%;
}
.btn-fi button.btn.btn-primary {
  width: max-content;
}
.multiselect-input > .multiSelectContainer {
  width: 100%;
}
.multiselect-input {
  display: flex;
  
  align-items: center;
}
.tox.tox-tinymce {
  min-height: 285px !important;
}
.trans-tb th, .trans-tb td {
  font-size: 13px;
}
.tox-editor-container {
  height: unset !important;
}

.overflow-set {
  padding-bottom: 285px !important;
}
.red-color{
  color: red !important;
}
.sticky-d{
  z-index: 0 !important;
}
.de-modal img {
  width: 70px;
  margin: 0 0 30px 0;
}
.disc-detail .form-control {
  height: 200px;
  overflow: auto;
  background: #f1f8fb;
  border-radius: 4px;
}
.switch-btn .clock-width label{
  padding-left: 0 !important;
}
.skill-type img {
  // width: 82px;
  // margin: 0 auto 20px auto;
  // display: block;
  width: 26px;
}
.skill-type {
  text-align: center;
}
.skill-type h4 {
  font-size: 20px;
  margin: 0 0 20px 0;
}
.bx-border {
  display: flex;
  flex-direction: column;
  border: 1px solid #00a4d630;
  padding: 18px;
  border-radius: 5px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.bx-border img {
  width: 160px;
  margin: 0 auto 30px auto;
  display: block;
}
.manger-title {
  margin: 0 0 40px 0;
}
.manger-title h4 {
  font-size: 22px;
  letter-spacing: .75px;
  color: #000000cc;
}
.bx-border button{
  margin-left: 0;
}
.time_entry {
  border: 1px solid #dbdbdb;

  border-radius: 5px;
}
.contract-top {
  padding: 14px;
  background: #f0f7ff;
  border-bottom: 1px solid #dbdbdb;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.modification-page :where(.css-dev-only-do-not-override-xu9wm8).ant-picker {

  border-radius: 35px !important;

}
.cont-logo img {
  width: 40px;
  height: 40px;
  padding: 7px;
}
.cont-logo {
  background: #d2caff;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}
.userNnames.clocktime {
  text-align: left;
}
.clocktime {
  font-weight: 400;
  text-transform: capitalize;
  color: gray;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
@media only screen and (max-width: 1499px) {
  .border,
  .subscribeButton {
    width: 100%;

    margin: 20px 0 0 0;
  }
}
@media only screen and (max-width: 1300px) {
  .navbar-expand-lg .navbar-nav .nav-link{
    padding: 0px 10px !important;
  }
  .heder_drop {
    padding: 6px 10px !important;
    font-size: 13px !important;

  }
  li.nav-item.nav-link.heder_drop {
    font-size: 13px !important; 
}

.messageUnread {
  top: -4px !important;
  right: -4px !important; 
  position: absolute;
}


}

@media only screen and (max-width: 1199px) {
  .myvirtualpalLogo151Icon{
    width: 108px !important;
  }
  .heder_drop {
    font-size: 12px !important;
    padding: 6px 10px  !important;

  }
  li.nav-item.nav-link.heder_drop {
    font-size: 12px !important;
}
.heder_drop {

  font-size: 12px !important;
}
.Sales_row.card h4 {
  margin: 0 0 12px 0;
  font-size: 16px;
  line-height: 23px;
  height: 40px;
}
.job-p-new {
  width: 40%;
}
.despcard.parah-ellipses {
  width: 55%;
}
.contract_box .contract-title h5 {
  // height: 55px;
  height: unset;
}
.card_hourstime .card_times_bx {
  font-size: 13px !important;
}
}
@media only screen and (max-width: 1024px) {


  .ceoCompany {
    font-size: 15px !important;
    white-space: unset !important;
  }
  .highestBid500Parent {
    flex-direction: column;
    align-items: center;
  }
  .findTheBest {
    font-size: 40px !important;
  }
  p.theMostTalented {
    font-size: 28px !important;
  }
 
}
@media only screen and (max-width: 991px) {
  .main_headindd {
    gap: 12px !important;
    flex-direction: column-reverse;
  }
  .footer {
    background: #222d38;
    padding: 60px 0 0 0 !important;
  }
  .parah-size {
    font-size: 12px;
  }
  .card-box > div {
    flex-direction: column;
}
.card-box input {
  margin: 0 0 20px 0;
}
.signup-modal .form-check {
  padding: 15px;
}
.signup-modal .form-check-label {
  margin-bottom: 0;
  font-size: 11px !important;
  text-align: center;
}
.desk-show {
  display: flex !important;
  align-items: center;
}
.mobile-show {
  display: none !important;
}
.notify-none {
  display: none;
}
.invite-div {
  justify-content: flex-start !important;
}
.second_flex span {
  width: 49%;

}
.col-md-reverse {
  flex-direction: column-reverse;
}
.skills-sets {
  margin: 11px 0 0 0;
}
.border-n{
  border-right: none !important;
}
h5.tital_heading {
  font-size: 12px !important;
}
.flex-diret-col {
  flex-direction: column;
}
}
@media only screen and (max-width: 767px) {
  .flex-custom {
    flex-direction: column;
    align-items: flex-start;
  }
  .flex-custom .form-check {
    margin: 10px 0 !important;
  }
  .testimonial-sect .slick-initialized .slick-slide {
    padding: 0 !important;
  }
  .recentlyPosted .slick-initialized .slick-slide {
    padding: 0 !important;
  }
  .testimonial3 .text {
    width: 100%;
  }
  .footer-top .text6 {
    margin: 10px 0;
  }
  .portfolios img.arrtoright {
    width: 32px;
    padding-right: 9px;
  }
  .landingPage2Inner .groupParent1 {
    width: 100%;
    margin: 10px 0;
  }
  .recentlyPosted {
    margin: 60px 0 0 0;
  }
  p.theMostTalented br {
    display: none;
  }
  p.theMostTalented {
    text-align: center;
    margin: 0;
  }
  .section-padding .ph_btn_align {
    align-items: center;
    justify-content: center;
    display: flex !important;
  }
  .section-padding .scheduleADemoWrapper.mr-2 {
    margin-bottom: 0;
  }
  .section-padding img {
    margin: 40px 0 0 0;
  }
  .misimg {
    margin: 0 auto;
    display: block;
  }
  .missionheader {
    text-align: center;
  }
  .missiondesc {
    text-align: center;
  }
  .bcm-partner {
    text-align: center;
  }
  .newgrow {
    margin: 0 0 20px 0 !important;
  }
  .profile_img_side {
    margin: 20px auto;
  }
  .image-flex {
    margin-top: 40px;
  }
  .col-reverse {
    flex-direction: column-reverse;
  }
  .blog-category {
    margin: 20px 0;
  }
  form.headerSearch {
    width: 100%;
  }
  .saved-job .post_btn {
    margin-top: 20px;
    margin-left: 0;
  }
  .saved-job .product_details {
    line-height: 21px;
    margin: 12px 0 0 0;
  }
  .new-wrap {
    flex-direction: column;
    align-items: flex-start !important;
    justify-content: flex-start;
  }
  .stick-position .sticky {
    position: unset;
    margin: 0 0 25px 0;
}
.filter-bottom .post_btn {
  margin-top: 19px;
  margin-left: 0;
}
.sidediv {
 border-left: 0 !important;

}
.propsed-detail {
  margin: 0 0 30px 0;
}
.main-table .cmc_table {

  
  width: 500px;
}
.main-table{
  overflow: auto;
}
.not_found_img {
  max-width: 100% !important;
}
.flex-lg-col {
  flex-direction: column;
  align-items: flex-start !important;
  justify-content: flex-start;
}
.contract-info {
  flex-direction: column;
  align-items: flex-start !important;
}
.signup-modal .form-check {
  width: 100%;
}
.signup-modal .form-check-label {
  margin-bottom: 0;
  font-size: 14px !important;
  text-align: center;
}
.space_canvas {
  width: 95% !important;
  border-radius: 20px 0px 0px 20px;
}
.reinvite-btn {
  margin: 20px 0 0 0 !important;
}
.rate_from {
  flex-direction: column;
}
.flex-xl-col .right {
  flex-direction: column;
  align-items: flex-start !important;
  width: 100%;
}
.col-changes {
  flex-direction: column;
  align-items: flex-start !important;
  justify-content: flex-start;
}
.col-changes h5{
  margin: 0 0 10px 0;
}
.job-p-new {
  width: 100%;
}
.despcard.parah-ellipses {
  width: 100%;
}
.flex-col-md {
  flex-direction: column;
}
.tabs-btn {

  width: 100%;
  margin: 10px 0 0 0;
}
.report_cardss {
  font-size: 13px !important;
}

}
@media only screen and (max-width: 480px) {
.hourly-rate{
  margin-top: 20px ;
}
.flex-md-col {
  flex-direction: column;
}
.space_canvas {
  width: 96% !important;

}
.second_flex span{
  width: 100%;
}
.upload-new-file {
  position: relative;
  margin: 4px;
}
.upload-drag.drag_drop {
  padding: 10px !important;
}
.upload-drag p {
  margin: 0 0 8px 0 !important;
}
.about_school iframe {
  width: 100%;
}
span.head-sub {
  width: 100% !important;
  display: block;
}
.contract-title {

  flex-direction: column-reverse;
}
.contract_box .contract-title h5 {
  height: unset;
  width: 100%;
  margin-top: 10px;
  font-size: 16px !important;
}
}
.fc-timegrid-event-harness > .fc-timegrid-event {
  inset: unset;
  position: absolute;
}
.fc .fc-timegrid-axis-cushion {

  font-size: 13px;
}
.fc .fc-timegrid-axis-cushion, .fc .fc-timegrid-slot-label-cushion {
  padding: 0px 4px;
  font-size: 13px;
  text-align: center;
  margin: 0 auto;
  display: block;
  font-weight: 500;
}
.fc .fc-col-header-cell-cushion {
  display: inline-block;
  padding: 2px 4px;
  font-size: 13px;
}
.fc-view-harness.fc-view-harness-active {
  height: 553px !important;
}
.fc-toolbar-chunk h2 {
  font-size: 20px !important;
}
.fc-v-event .fc-event-main-frame {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.fc-timegrid-event-harness {
  position: absolute;
  width: 100%;
}
.fc .fc-timegrid-slot {
  border-bottom: 0px;
  height: 6.5em;
}
span.blue-color {
  color: #00a4d6;
}





// gourav css 

.dots_removes ul.dropdown-menu.show {
  position: absolute !important;
  transform: translate(0px, 23px) !important;
  width: 100px;
  right: 0px !important;
  margin-left: auto !important;
}

.dots_removes {
  position: relative;
}

.filter_bad{
  margin: 0px !important;
}

// profile page css


.profilefadea .profile_btn_portfolio{

  width: 100% !important;
  height: 100% !important;
  margin: 0rem 0rem !important;
}

.profilefadea .exmp{
  font-size: 13px;
  color: #ff0000ab;
}

.deleteptag .delet_icon {
  position: absolute;
  top: -3px;
  right: -11px;
  width: 20px !important;
  height: 20px !important;
  font-size: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  cursor: pointer !important;
}
.deleteptag .delet_icon:hover {
  background-color: #e5e5e5;
}

.imagesRow {
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
}

.main_tabs .text_box {
  padding: 6px 21px;
  background-color: #D9D9D9 ;
  color: #414141 !important;
  border-radius: 40px;
  font-size: 14px;
  font-weight: 500;
}

.main_tabs li a.text_box:hover{
  background-color: #dbd4d4 !important ;
  border-radius: 40px !important;
  color: #414141 !important;
}
.main_tabs .text_greens{
  color: #fff;
  background-color: #00a5d9 ;
  border-color: #00a5d9;
  padding: 6px 21px;
  border-radius: 40px;
  font-weight: 500;
  font-size: 14px;
  color: #fff !important
}

.main_tabs li a.text_greens:hover{
  background-color: #00a5d9 !important;
  border-radius: 40px !important;
  color: #fff !important;
}
.tbsectionsd td, .tbsectionsd th {
  padding: 5px 12px !important;
  border-radius: 0px !important;
  font-size: 14px;
  border: 1px solid #dee2e6 !important; 
}
.tbsectionsd {margin-bottom: 16px;}

:where(.css-dev-only-do-not-override-xu9wm8).ant-picker-dropdown .ant-picker-cell .ant-picker-cell-inner{
  text-align: center;
}
.activespan {
  font-size: 12px;
  border-radius: 5px;
  padding: 2px 10px;
}

.outline_pri {
  color: #5ba5d8 !important;
  border: 1px solid #5ba5d8 !important;
  font-weight: 500;
  background-color: #fff;
}

.padding_lgin{
  padding-right: 34px !important;
}

.fc-button-group button {
  background-color: #00a5d9 !important;
  border: 1px solid #ffffff !important;
}

.fc-button-group button:focus {
  box-shadow: unset !important;
}


button.fc-today-button.fc-button.fc-button-primary {
  background-color: #00a5d9;
  opacity: 1;
  border: 1px  solid #00a5d9;
  text-transform: capitalize;
}



.main_switch label.clock-div {
  font-size: 21px;
  font-weight: 600;
  line-height: 19px;
  margin-bottom: 9px !important;
}

.main_switch .timer_div, .main_timer {
  font-size: 14px;
}

.mai_text table th, .mai_text table td{
  text-align: center !important;
}

.notifications_ptag {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}


.notifictons_bells br {
  display: none;
}

.jobmain textarea.form-control {
  padding: 16px 15px;
  background-color: #FAFAFA;
  border-radius: 10px !important;
}

label.Job_titles {
  font-size: 18px;
  font-weight: 500;
}

.main_jobsara .searchWrapper {
  border: 1px solid #ccc !important;
  border-radius: 10px !important;
  padding: 14px;
  position: relative;
  background-color: #FAFAFA;
  min-height: 50px;
}

.softskillsdd .css-13cymwt-control, .softskillsdd  .css-qbdosj-Input {
  border-radius: 10px !important;
  min-height: 41px !important;
  background-color: #FAFAFA;
}



.softskillsdd .css-t3ipsp-control:hover, .softskillsdd .css-t3ipsp-control:focus, .softskillsdd .css-qbdosj-Input:hover, .softskillsdd .css-qbdosj-Input:focus {
  border-radius: 10px !important;
  min-height: 41px !important;
  background-color: #FAFAFA;
  box-shadow: unset !important;
outline: unset !important;
}

.softskillsdd .select_textbxx {
  min-height: 136px !important;
}
.css-1u9des2-indicatorSeparator{
  width: 0px !important;
}

.mmddd_positon {
  position: relative;
}

.mmddd_positon .hard_down {
  position: absolute;
  top: 14px;
  right: 17px;
  font-size: 22px;
  z-index: 1;
}

.mmddd_positon .css-tj5bde-Svg {
  z-index: 12;
  opacity: 1;
  margin-right: 0px;
}
.mmddd_positon .css-tj5bde-Svg path:hover{
  opacity: 1;
}
.attach_filess{
  background-color: transparent;
  font-size: 16px;
  color: #00a5d9 !important;
  padding: 6px 12px;
  border: 0px;
  display: flex;
  align-items: center;
}
span.material-icons.me-2.attachment {
  transform: rotate(95deg);
}

.main_rate-fomrs {
  width: 100%;
}

.full_div .rate_from input {
  width: 100% !important;
}

.Hourlydd {
  font-size: 16px;
  border-radius: 10px !important;
}



.averagerate {
  font-size: 14px;
}


.full_div input.form-control.mt-1.mr-2 {
  min-height: 51px;
  border-radius: 10px !important;
}


.nav_barbs .nav-item {
  height: 37px;
}

.offer_buttons {
  justify-content: end;
  display: flex;
  gap: 13px;
}

.offer_buttons button{
  border-radius: 50px;
}

//contract page card css

.card_contract {
  border-radius: 12px;
  background-color: #5180c7;
  padding: 20px;
  margin-top: 17px;

}
.timer_titlesbx .timer_div {
  font-size: 22px;
}

.set_cardbx {
  padding: 0px 0px 10px;
}

.set_cardbx 
.card_titles {
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  margin-bottom: 6px;
}

.set_cardbx .light_btns {
  border: 1px solid #fff;
  background-color: #fff;
  border-radius: 27px;
  font-size: 12px;
  position: relative;
  padding: 4px;
  font-weight: 500;
  width: 77px;
  padding-left: unset;
}

.timer_titlesbx .timer_cards {
  font-size: 24px;
  color: #fff;
  padding: 9px;
  font-weight: 500;
  border-bottom: 1px solid #d7d7d7;
}

.set_cardbx .dots_card {
  width: 10px;
  height: 10px;
  display: block;
  position: absolute;
  border-radius: 27px;
  background-color: #c3b9b9;
  top: 9px;
  left: 10px;
}

.address_cards {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 16px;
  margin-top: 15px;
  color: #fff;
}

.card_hourstime .card_titles {
  font-size: 14px;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  // width: 250px;
  color: #fff;
  
}

.card_hourstime .card_times_bx {
  color: #fff;
  font-weight: 600;
  font-size: 15px;
}

.card_maps {
  font-size: 14px;
  margin-right: 5px;
  color: #fff;
}
.searcbtns {
  display: flex;
  gap: 7px;
  align-items: center;
 
}

@media (max-width:576px){

  .card_hourstime {
    flex-direction: column;
    border: 1px solid #fff;
    padding: 10px;
    border-radius: 9px;
}

.timer_titlesbx .timer_cards {
    border-bottom: unset !important;
    padding: 0px;
}

.searcbtns .btn-fi{
  margin-left: auto !important;
}
.searcbtns{
  flex-wrap: wrap;
}
}

.borderadius {
  border-radius: 18px;
  padding: 10px;
}

.borderadius p {
  margin-bottom: 0px;
}


.set_postion .tracker_switch {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #6f99fb;
  border: 1px solid #fff;
}



.allInpus input[type="checkbox"] {
  position: relative;
  width: 45px;
  height: 24px;
  appearance: none;
  background: #ffffff;
  outline: none;
  border-radius: 2rem;
  cursor: pointer;
}

.allInpus input[type="checkbox"]::before {
  content: "";
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background: #5ba5d8 !important;
  position: absolute;
  top: 1px;
  left: 1px;
  transition: 0.5s;
}

.allInpus {
  text-align: end;
}

.allInpus input[type="checkbox"]:checked::before {
  transform: translateX(95%);
  background: #ffffff !important;
}

.allInpus input[type="checkbox"]:checked {
  background: #5ba5d8;
}


///////////////

.allInpus input.set_ins {
  border: 1px solid #6280c67a;
}


.allInpus input.set_ins[type="checkbox"] {
  position: relative;
  width: 45px;
  height: 24px;
  appearance: none;
  background: #ffffff;
  outline: none;
  border-radius: 2rem;
  cursor: pointer;
}

.allInpus input.set_ins[type="checkbox"]::before {
  content: "";
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background: #5ba5d8 !important;
  position: absolute;
  top: 0px;
  left: 0px;
  transition: 0.5s;
}

.allInpus {
  text-align: end;
}

.allInpus input.set_ins[type="checkbox"]:checked::before {
  transform: translateX(95%);
  background: #ffffff !important;
}

.allInpus input.set_ins[type="checkbox"]:checked {
  background: #5ba5d8;
}


.css-t3ipsp-control {
    border-radius: 37px !important;
    border: 1px solid gainsboro !important;;
   box-shadow: unset !important;
   font-size: 14px;
}
.select_itsmx .css-13cymwt-control {
    font-size: 14px;
}
.css-1nmdiq5-menu {
    font-size: 13px !important;
}

.btn_info {border: 1px solid #00a5d9 !important;color: #00a5d9 !important;}

.btn_info:focus, .btn_info:hover {background-color: #00a5d9 !important;color: #fff !important;}

.btn_info:active {background-color: #00a5d9 !important;color: #fff !important;}

.mmddd_positon .css-inmdiq5-menu{
  z-index: 999 !important;
}



.nav_barbs {
  border: 1px solid #dfdfdf;
  background-color: #fff;
  border-radius: 38px;
  padding: 7px;
}

.nav_barbs .nav-link.active, .nav_barbs .show > .nav-link {
  background: #4ba5d9 !important;
  color: #fff;
  box-shadow: none;
  border-bottom: 0px solid #4ba5d9 !important;
  border-radius: 55px;
  padding: 6px 26px;
}

.nav_barbs .nav-link.active, .nav_barbs .show > .nav-link {
  background: #4ba5d9 !important;
  color: #fff !important;
  box-shadow: none;
  border-bottom: 0px solid #4ba5d9 !important;
  border-radius: 55px;
  padding: 6px 26px;
}
.nav_barbs .nav-link.active:hover, .nav_barbs .nav-link.active:focus{
  background: #4ba5d9 !important;
  color: #fff !important;
  border-radius: 55px !important;
  padding: 6px 26px !important; 
}
.nav_barbs  .nav-link:focus-visible{
  box-shadow: unset !important;
}
.nav_barbs  .nav-link{
color: #000;
border-radius: 55px !important;
padding: 6px 26px !important;
}
.heder_drop {
  padding: 6px 16px ;
}

//profile page css 

.upload_doc .profile_btn_portfolio {
  width: 100%;
}

.upload_doc label.add_portfolio.edit {
  height: auto !important;
}


.close_doc {
  position: absolute;
  top: 0px;
  right: -9px;
  font-size: 12px;
  cursor: pointer;
  color: gray;
}

.close_doc:hover {
  color: #c13b45 !important;
}

.profess_ions .optionListContainer {
  width: 100% !important;
  font-size: 14px !important;
}

.profess_ions .searchWrapper {
  border-radius: 10px !important;
}

.video_intro .modal-content .ViewUser {
  font-size: 20px;
  font-weight: 500;
}

.all_portofolio .profile_btn_portfolio {
  width: 100%;
}

.closeporto, .close_resume {
  position: absolute;
  top: 2px;
  right: -3px;
  font-size: 12px;
  cursor: pointer;
  color: gray;
}
.closeporto:hover, .close_resume:hover{
  color: #c13b45 !important;
}

.resume_pagesw label.add_portfolio.edit {
  height: 100%;
}

.main_headindd {
  height: auto !important;
}


.report_cardss {
  text-transform: capitalize ;
  font-size: 14px;
  padding: 10px 0px !important;
}
h3.head{
  margin-bottom: 0px;
}

.pagination .back_page.disabled {
  display: none;
}


span.badges {
  background: #E3E3E3;
  color: #000;
  padding: 3px 15px;
  border-radius: 50px;
  margin-bottom: 5px;
  display: inline-block;
  font-weight: 400;
  margin-right: 10px !important;
  margin-left: 0px !important;
}
.set_jobss span {
    font-size: 12px !important;
    padding: 4px 8px !important;
}
.p_titles, .perviwbx label{
  text-transform: capitalize;
  font-size: 14px;
}
.p_titles p{
  margin-bottom: 0px;
}




//
.img_name {
  width: 40px;
  height: 40px;
  display: flex;
  background-color: #795548d4;
  justify-content: center;
  align-items: center;
  border-radius: 33px;
  font-size: 14px;
  color: #fff;
  font-weight: 600;
}

.userNnames {
  font-size: 14px;
  font-weight: 600;
  margin: 0px;
}

h3.edit_shift {
  font-size: 14px;
  color: gray;
  margin: 0px;
  font-weight: 500;
}

h3.user_chat {
  font-size: 13px;
  font-weight: 400;
  margin: 0px;
  color: #5ba5d8;
}

.messagess {
  margin-right: 9px;
  font-size: 13px;
}
.noteclass{
  font-size: 14px;
  white-space: nowrap;
  font-weight: 400;
}
.deletion_requst {
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  text-decoration: underline;
}



.time_details {
  font-size: 12px;
  border: 2px solid red;
  border-radius: 8px;
  color: red;
  display: flex;
  padding: 7px;
}
.detaisl {
  display: flex;
  gap: 0px;
  flex-wrap: wrap;
}
.btn_aproves button{
  border: 1px solid #c9b6b5;
  border-radius: 33px;
  padding: 6px 22px;
  background-color: #fff;
  font-size: 14px;
  vertical-align: sub;
  max-width: 149px;

  width: 100%;
}
.btn_aproves .colorcheck {
  margin-right: 9px;
}
.aprove{
  color: #5ba5d8;

}
.Decline{
  color:#ff0000ad;
}
.btn_aproves .colorwrong {
  margin-right: 9px;
}
.set_timess {
  width: 147px;
  margin-top: 6px;
  font-size: 14px;
}
span.redtime {
  white-space: nowrap;
  font-weight: 600;
  font-size: 15px;
  color: #ff0000ad;
}

.ligt_texttime {
  font-size: 13px;
  font-weight: 500;
  color: gray;
  white-space: nowrap;
  position: relative;
  display: flex;
  align-items: center;
}
.ligt_texttime::before {
  content: " ";
  width: 7px;
  height: 7px;
  background: #8888b1;
  display: block;
  border-radius: 100%;
  margin-right: 4px;
}
.small_redtime{
  font-size: 13px;
  color: #ff0000ad;
  white-space: nowrap;
}

.all_greenbx .time_details {
  font-size: 12px;
  border: 2px solid red;
  border-radius: 8px;
  color: #0eb80e;
  display: flex;
  padding: 7px;
}
.all_greenbx span.redtime {
  white-space: nowrap;
  font-weight: 600;
  font-size: 15px;
  color: #3cc93c;
}
.form-row .userNnames{
  text-align: center;
}
span.redtime {
  white-space: nowrap;
  font-weight: 600;
  font-size: 15px;
  color: #ff0000ad;
  margin: 2px 0;
}

.arrow-set i{
  font-size: 13px;
}
span.date-font {
  font-size: 14px;
}
.rests {
  margin-top: 5px;
}

.rests .userNnames {
  font-size: 14px;
  font-weight: 500;
  margin-top: 12px;
}

.rests input{
  border: 1px solid gray;
  padding: 3px 12px;
}
.clocktime {
  font-weight: 400;
  text-transform: capitalize;
  color: gray;
}

.serach_btns .fa-search{
  left: 14px !important;
}
.clear_bss {
  margin-top: 30px !important;
}

.time-logged h6 {
  background: #00a4d6;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
  color: #fff;
}
.ellipses{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  height: 58px;
  font-size: 20px;
  line-height: 30px;
}
.blog-flex{
  display: flex;
  align-items: center;

}
.date-blog{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.date-blog p {
  font-size: 12px;
  margin: -0;
  color: #616161;
  display: flex;
  align-items: center;
}
.blog-inner h2 {
  font-size: 21px;
  margin: 20px 0 0 0;
}
.dropdown-category button{
  height: 40px;
}
.blogs-main-page i.fa.fa-arrow-left {
  color: #000 !important;
}
.recent-blogs-inner {
  border: 1px solid #d7d7d7;
  border-radius: 8px;
  padding: 10px;
}
.recent-blogs-inner img {
  width: 100%;
  height: 150px;
  object-fit: contain;
}
.recent-blogs-inner h4 {
  font-size: 17px;
  height: 22px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.recent-blogs-inner p{
  font-size: 12px;
  margin: 0;
  height: unset;
  line-height: 16px;
}
.related-blogs h3 {
  font-size: 20px;
  margin: 0 0 20px 0;
}
.input-set{
  width: 30px;
}
.input-radius{
  border-radius: 50px;
}
.flex-property{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background: #efefef;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom: 1px solid #dcdcdc;;
}
.referral-credit {
  border: 1px solid #dcdcdc;
  
  border-radius: 8px;
}
.flex-amt {
  padding: 10px;
  font-size: 14px;
}
.refer-price.amiunt {
  font-weight: 500;
}
.pay-modal .modal-header{
  border-bottom: 1px solid #dcdcdc;;
}
.clock-switch{
  width: 60px !important;
  height: 26px !important;
}
@media only screen and (max-width:767px){
  .contract-top .w-50{
    width: 100% !important;
  }
  .contract-top{
    flex-direction: column ;
  }
  .contract-top .btn_aproves {
    justify-content: flex-start !important;
    margin: 10px 0 0 0;
  }
  .time_entry .border-left {
    border-left: 0 !important;
   
}
}
.set-on-off {
  // border: 1px solid green;
  // padding: 0px 6px;
  border-radius: 4px;
  // color: green !important;
  font-size: 18px !important;
  font-weight: 600 !important;
}
span.bg-button{
  font-size: 13px;
  background: green;
  padding: 0px 11px;
  border-radius: 3px;
  color: white;
}
span.off-button {
  font-size: 13px;
  background: grey;
  padding: 0px 11px;
  border-radius: 3px;
  color: white;
}
span.set-off {
  // border: 1px solid grey;
  padding: 0px 6px;
  border-radius: 4px;
  color: grey !important;
  font-size: 18px !important;
  font-weight: 600 !important;
}
h3.referrals {
  font-size: 19px;
  font-weight: 600;
}
.Sales_row.card {
  box-shadow: 0px 1px 2px 1px #8080801f;
  border: 1px solid #80808029;
}
.set-bg-clr {
  background: #80808026;
  border: 1px solid #8080802b;
  border-radius: 5px;
  height: 100%;
  width: 100%;
  position: relative;
  text-align: center;
}
img.emp-img {
  width: 84px;
  height: 70px;
}
.set-pos {
  position: absolute;
  right: 0;
  top: 0;
}
.set-bg-clr label {
  font-size: 17px;
  font-weight: 600;
}
.videos video{
  width: 100% !important;
}
.videoTrash{
  // position: absolute;
  font-size: 12px !important;
  background: red;
  padding: 5px !important;
  border-radius: 5px !important;
  color: #fff !important;
}