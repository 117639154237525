// img.virtual_user {
//   width: 100%;
//   height: 200px;
//   object-fit: cover;
// }

.box_clas {
  border-top: 1px solid #80808038;
  // box-shadow: 0px 0px 4px 0px #8080803d;
}
.name_cls{
  font-size: 13px;
  font-weight: 600;
  color: #4ba5d9;
}
.search_virtual_list input {
  border-radius: 10px !important;
  border: 1px solid rgba(0, 0, 0, 0.10);
  background: #FFF;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.10);
}
.btnIconAppend button {
  border-radius: 10px !important;
  padding: 8px;
  position: relative;
  right: 8px;
  z-index: 999 !important;
}
span.filter_badges {
  border-radius: 40px;
  background: #20A2DC;
  padding: 3px 12px;
  color: #fff;
  margin-right: 10px;
  margin-bottom: 10px;
}


span#basic-addon1 {
  font-size: 12px;
  padding: 0px 10px;
}
.input_max{
  width: 125px !important;
}
h3.skilssty {
  font-size: 10px;
  font-weight: 300;
  // color: #626262;
  color: #000;
}
.starVerified.ml-2.mr-2 {
  display: flex;
}
.Proposals_bottom{
  font-size: 12px;
}
img.virtual_user {
  width: 60px;
  border-radius: 48px;
  height: 60px;
  object-fit: cover;
}
.mainfind {
  border: 1px solid gray;
}

p.search_data {
  color: #4d4d4d;
  font-weight: 600;
}
.bg_light_page{
  background: #F4FAFA;
  min-height: calc(100vh - 83px);
}
.bggray {
  // background: #f4fafa;
  position: sticky;
  top: 6px; 
  // border-right: 1px solid #ccc;
}
 .headign_Cat {
  font-weight: 500;
  font-size: 15px;
  position: relative;
  margin-bottom: 15px;
  color: #000;
}
.p-3.bg-white .input-group {
  line-height: 1.5;
}
.p-3.bg-white {
  border: 1px solid #ededed;
}
.input-group-text {
  background: #fff; 
  color: #00a5d9;
}
span.mx-1.text-capitalize {
  background: #E3E3E3;
  // color: #3c3c3c;
  color: #000;
  padding: 3px 15px;
  font-size: 10px;
  border-radius: 50px;
  margin-bottom: 5px;
  display: inline-block;
  font-weight: 400;
  margin-right: 10px !important;
  margin-left: 0px !important;
}
p.Proposals.mt-3 {
  font-size: 13px;
}
span.blur_time_ago {
  font-size: 13px;
  // color: #A9A9A9;
  color: #000;
}
.input-group.mb-3 input {
  border-left: none;
  padding-left: 0px;
}
.sticky {
  position: sticky;
  top: 0;
}
button.btn.btn-outline-primary.header_like:hover span {
  color: black;
}
a.accordion-button {
  background: #fff !important;
}
.accordion-item {
  border: none;
  background: transparent;
}
a.accordion-button {
  background: transparent !important;
  box-shadow: none !important;
  padding: 0px !important;
}
.accordion-body {
  padding: 20px 0px !important;
}
label.mb-1 {
  // color: #717171;
  color: #000;
  position: relative;
  // top: 3px;
}
.sticky_search_area {
  position: sticky;
  top: 0px;
  background: #fff;
  z-index: 999;
  width: 100%;
  max-width: 100%;
  border-radius: 24px 24px 0px 0px;
}
img.Verified {
  height: 15px;
  width: 15px;
  object-fit: contain;
}
.starVerified span {
  font-size: 12px;
  color: orange;
}
.starVerified .fa-star {
  padding: 0px 2px;
}
p.name.mb-0 {
  font-size: 16px;
  font-weight: 500;
}
.main_shadow {
  box-shadow: 0px 0px 7px 0px #80808054;
  padding: 0px;
  border-radius: 25px;
  background: #fff;
  position: relative;
  z-index: 999;
}
.searchfield{
width: 596px;
// box-shadow: 0px 0px 5px 0px #80808029;
}


// .headign_Cat:after {
//   position: absolute;
//   content: '';
//   width: 61px;
//   height: 2px;
//   background: #3aa0dc;
//   left: 0;
//   bottom: -7px;
// }
span.rate {
  font-size: 13px;
  color: #000000f2;
  font-weight: 600;
}
// span.rate {
//   font-size: 20px;
//   color: #e35d37;
//   font-weight: 600;
// }
// h4.mt-3.text-capitalize.d-flex.justify-content-between {
//   font-size: 22px;
// }

 .description_cls {
  font-size: 14px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 500px;
}
@media(max-width:820px){
  .description_cls{
    max-width: 100px;
  }
}
.btn_virtual button{
  text-transform: capitalize;
  font-weight: 500;
  padding: 5px 10px;
  font-size: 14px;
  border-radius: 6px;
}
.v_name {
  margin: 0;
  color: #000000;
  font-weight: 400;
  font-size: 12px;
}
.v_desc {
  margin: 0;
  color: #000000;
  font-weight: 600;
  font-size: 15px;
}
.message_btn {
  background: none;
  color: #20A2DC;
  font-weight: 500;
  border-color: #20A2DC;
  font-size: 12px;
  width: 115px;
  border-radius: 5px;
}
.message_btn:hover {
  background: #20A2DC;
  border-color: #20A2DC;
}
.invite_btn{
  font-size: 12px;
  width: 115px;
  border-radius: 5px;
}
.invite_btn:hover{
background: none !important;
border-color: #20A2DC !important;
color: #20A2DC !important;
}
.second_flex {
  display: flex;

  align-items: center;
  flex-wrap: wrap;
}
.second_flex span {
  font-weight: 500;
  color: #000000;
  font-size: 14px;
}
.third_flex {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}
.third_flex span {
  background: #20A2DC;
  color: #fff;
  padding: 5px 15px;
  border-radius: 20px;
  font-size: 10px;
}
.v_details {
  font-weight: 400;
  color: #000000;
  font-size: 12px;
  line-height: 18px;
  display: -webkit-box;
   max-width: 850px; 
  //  -webkit-line-clamp: 4; 
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.see_it {
  border: none;
  background: none;
  color: #20A2DC;
  font-weight: 500;
  
}
.main_rowit{
  border-top: 1px solid #80808038;
}
.advance_btn {
  border-radius: 7px;
  font-size: 14px;
}