@import '../../../scss/mixins';
a.btn.barlink.text-primary {
  position: absolute;
  left: 6px;
  z-index: 9;
  top: 21px;
}
i.far.fa-credit-card.mr-1 {
  font-size: 13px;
  margin-right: 6px !important;
}
// new style
.heder_drop {
  // background: #f3fafa;
  background: transparent;
  border: none;
  color: #000;
  font-weight: 400;
  font-size: 14px;
}
.search_drop{
   background: #f3fafa;
  border: none !important;
  color: #0000008c;
}
.header_menu{
  border-radius: 13px;
}

.search_drop:hover{
  background: #3aa0dc21 !important;
border:none; 
color: #0000008c !important;
}
.heder_drop:hover {
  // background: #f3fafa !important;
  background: none !important;
  border: none;
  color: #000;
  font-weight: 400;
  font-size: 14px;
}
li.nav-item.nav-link.heder_drop {
  font-size: 14px;
  color: #000;
  background: transparent;
  position: relative;
  
}
.prodiv_cl{
  min-width: 15rem !important;
}

// .col-md-12.border-bottom.mt-3.pt-3.mb-3.pb-3.jobsearch_box {
//   border-radius: 20px 20px 0px 0px;
// }
.innerpaira {
  margin-left: 2rem !important;
}

.dropicon {
  width: 22px;
}


.dropdown-menu.jobmenu {
  border-radius: 12px;
  left: inherit !important;
    right: 0 !important;
}
.dropicon {
  font-size: 18px;
}

.Searchbar:hover {
  // background: #3aa0dc21 !important;
border:none 
}
form.headerSearch {
  border: 1px solid #cccccc;
  padding: 3px 3px;
  border-radius: 60px;
  background: #f9f9f9;
}
img.rounded-circle.drop_innerimg {
  width: 90px;
  height: 90px;
  object-fit: cover;
}

.bg_hover .dropdown-item:focus, .dropdown-item:hover {
  background-color: #cdf0fa80;
  color: #0076b5 !important;
  border-radius: 8px;
}

.dropgray {
  font-size: 13px;
  // color: gray;
  color: #000;
}
::placeholder {
  color: #000 ;
  opacity: 1; /* Firefox */
}

::-ms-input-placeholder { /* Edge 12 -18 */
  color: #000 ;
}

.heder_drop:hover{
  // background: #f3fafa !important;
  background: none !important;
  color: #000;
border:none 
}
.btn-secondary:not(:disabled):not(.disabled).active, .btn-secondary:not(:disabled):not(.disabled):active, .show>.btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #f3fafa;
  border-color: #f3fafa;
  color: #000;
}
.btn.btn-secondary.dropdown-toggle.heder_drop:focus{
border: none !important;
}

.heder_drop:hover {
  // background: #f3fafa;
  background: none !important;
  border: none;
  color: #4eade0 !important;
  // font-size: 15px;
  font-weight: 400;
}
// end
.headerChild:hover {
  // box-shadow: 1px 2px 3px #f3fafa;
}
.navbar-light .navbar-nav .nav-link a{
  color: rgba(0,0,0,.5) !important;
}
.bg_hover .dropdown-item:focus, .dropdown-item:hover {
  background-color: #3aa0dc21;
  color: #0076b5 !important;
  border-radius: 4px;
  padding: 0.25rem 1rem !important;
}
// .headerItem:hover {
//   box-shadow: 1px 2px 3px #ccc;
// }
.dropdown-menu.rightDrop_down.show {
  left: inherit !important;
  right: 7px;
  border: none;
  padding: 14px 0px;;
}
.bg-light {
  // background-color: #f3fafa!important;
  background-color: #3aa0dc2e !important;
}
.headerChild, .headerItem{
  text-align: center;
  margin-right: 10px;
  border-radius: 6px !important;
}
.innerpaira {
  color: gray;
}
span.innertl {
  font-size: 16px;
  font-weight: 500;
  color: #000000d1;
}
a.btn.barlink.text-primary {
  left: 20px;
  width: 35px;
  height: 35px;
  padding: 7px 9px;
}
.headerSearch {
  left: 6px;
}
.ml-1.nameFont b {
  font-size: 14px;
  font-weight: 500;
  color: #fff;
}
.ml-1.nameFont p{
  font-size: 12px;
  font-weight: 400;
  color: #fff;
}
.dropdown-item { 
  padding: 0.25rem 1rem !important;
}
.grayCls{
  // color: #84818A;
  color: #000;
}
.ml-1.nameFont {
  line-height: 18px;
}
.sidebar-brand.p-3.pt-4.text-left.pl-5 {
  // border-bottom: 1px solid #E8E8E8;
  padding: 0px !important;
  height: 72px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  padding-left: 50px !important;
}
i.fas.fa-bars{
  // color: #84818A;
  color: #000;
  font-size: 18px;
}
.main-navbar {
  // background-image: linear-gradient(to left, #2062AD, #24A2DB);
  // background-color: #fff;
  color: #fff;
  height: 72px; 
  padding-top: 0px;
  padding-bottom: 0px;
  border-bottom: 1px solid #E8E8E8 !important;

  &.min-sidebar {
    padding-left: 60px;
  }

  .nav-link {
    padding: 10px;
  }

  .nav-link-user {
    >img {
      width: 32px;
      height: 32px;
    }
  }
}
input.Searchbar:focus {
  outline: none;
}
.headerSearch {
  position: relative;
  font-size: 14px;
  color: #000;
 

  .Searchbar {
    background: #F9F9F9;
    border: none !important;
    padding: 10px 35px;
    border-radius: 8px;
    height: 32px;
    font-size: 14px;
    font-weight: 400;
  }

  >i {
    position: absolute;
    top: 50%;
    // color: #84818A;
    color: #000;
    transform: translateY(-50%);
    cursor: pointer;

    &.fa-search {
      left: 10px;
    }

    &.fa-times {
      right: 10px;
    }
  }
}

.notification {
  color: #CACCCF;
  font-size: 27px;
  transform: rotate(29deg);
  margin-right: 22px;
  margin-top: 16px;
}

.reddot {
  background-color: red;
  padding: 2px 5px;
  border-radius: 14px;
  color: #ffff;
  font-size: 12px;
  font-weight: 600;
  position: absolute;
  top: -13px;
  right: -2px;
  border: 3px solid #ffff;
  transform: rotate(333deg);
}
.min-sidebar ~ div > .main-sidebar a.nav-link.hoverclass {
  padding: 8px;
  border-radius: 6px !important;
  margin-right: 10px !important;
  width: 41px;
  justify-content: center;

}
li.licls {
  list-style: none;
}

.min-sidebar ~ div > .main-sidebar a.col_side {
  padding: 8px;
  border-radius: 6px !important;
  margin-right: 10px !important;
  width: 41px;
  justify-content: center;

  &:hover{
    justify-content: center;
    margin-right:11px !important;

  }

}

.min-sidebar {
  ~div>.main-sidebar {
    width: 65px;
    min-width: 58px;

    .sidebar-brand {
      width: 0;
      visibility: hidden;
    }

    .nav-link span {
      display: none;
    }
    .nav-link.hoverclass{
      justify-content: center !important;
      
    }
    & i{
margin-right: 0px ;
    }
    & i.sidenv{
      display: block !important;
      margin-right: 0px  ;
  }
  
  }

  ~div>main.main {
    width: calc(100% - 58px);
  }
}

.min-sidebar {
  ~div>.main-sidebar {
    width: 65px;
    min-width: 58px;

    .sidebar-brand {
      width: 0;
      visibility: hidden;
    }
    .submenu {
      display: block;
  }
    a.col_side span {
      display: none;
    }
    .card.card-body.sides{
      background-color: transparent;
    }
  }

  ~div>main.main {
    width: calc(100% - 58px);
  }
}







li.nav-item.nav-link {
  cursor: pointer;
}
li.noti_fication {
  list-style: none;
}
.remove_after:after{
  display: none !important;
}
.remove_after span {
  font-size: 24px;
}
a.dropdown-toggle.position-relative.remove_after:hover {
  background: transparent !important;
}
span.red_dot {
  height: 24px;
  width: 25px;
  background: #ea5c32;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -16px;
  right: -8px;
  border-radius: 50%;
  border: 1px solid #fff;
  text-align: center;
  padding: 4px;
  font-size: 10px;
  padding-top: 5px !important;
  color: #fff;
}
img.small_user {
  width: 35px;
  height: 35px;
  border-radius: 50px;
  object-fit: cover;
}
p.ml-3.noti_data {
  word-break: break-word;
  white-space: break-spaces;
  line-height: 1.5;
  margin-bottom: 0px;
}
li a:hover {
  border-radius: 0px !important;
  color: #000 !important; 
  background-color: #61cbe921 !important;
}
ul.dropdown-menu.noti_dropdown {
  width: 300px;
  // max-height: 300px;
  // overflow: visible;
  left: inherit;
  right: -6px;
  border-radius: 15px;
}
ul.dropdown-menu.noti_dropdown.show:before {
  content: "";
  display: block;
  position: absolute;
  top: -7px;
  right: 2px; 
  border-left: 8px solid #fff;
  border-bottom: 8px solid #fff;
  border-right: 8px solid transparent;
  border-top: 8px solid transparent;
  transform: translateX(-50%) rotate(135deg);
  box-shadow: -2px 2px 3px rgba(57,73,76,.1);
}
p.dropdown-item.see_All {
  margin-bottom: 10px;
  padding: 15px !important;
  font-weight: 500;
}
.noti_dropdown li a {
  padding-top: 15px !important;
  padding-bottom: 10px !important;
}
.noti_dropdown li a:hover {
  padding-top: 15px !important;
  padding-bottom: 10px !important;
}
// Header css
.myvirtualpalLogo151Icon {
 display: block;
  width: 130px;  
  object-fit: cover;
}
// .navbar-expand-lg .navbar-nav .nav-link {
//   padding-right: 1.5rem !important;
// }

.navbar-expand-lg .navbar-nav .nav-link {
  padding: 0px 16px ;
}
 
 
.headerChild,
.headerItem { 
  width: 107px;
  height: 35px;
  display: flex;
    align-items: center;
    justify-content: center;
}
.headerChild {
  //  background-color: var(--color-deepskyblue-100);
  // color: #fff !important;
  font-size: 15px;
}
.headerItem {
  left: 1003px;
  //  background-color: #d9d9d9; 
  font-size: 15px;
  color: #000;
}

.login { 
  font-size: var(--font-size-base);
  color: var(--white);
}
 
.signUp { 
  font-size: var(--font-size-base);
  color: #414141;
}
.header { 
  width: 100%;
  // height: 35px;
  font-size: var(--font-size-lg);
  color: var(--color-gray-200);
}
.relateit{
  position: absolute !important;
}
@media screen and (max-width: 991px) {
  div#navbarSupportedContent {
    background: white;
    padding: 13px;
    max-height: 301px;
    width: 100%;
    border-bottom: 1px solid #3aa0dc78;
    z-index: 9;
}
.signup-btn button {
  margin: 10px 0 0 0;
}
ul.header.navbar-nav.justify-content-left.align-items-center {
  text-align: left;
  display: inline-block;
}
.heder_drop {
  background: #ffffff; 
  padding-left: 0px;
}
li.noti_fication {
  // text-align: right;
  text-align: center;
}
button.navbar-toggler {
  position: relative;
  left: 0px;
}
}
@media(max-width:480px){
  .myvirtualpalLogo151Icon {
    width: 80px;
}
  .headerleft {
    margin-left: 0 !important;
}
.headerleft .nav-link-user {
  padding-right: 0;
}
.navbar-toggler {
margin-left: auto !important;
}
div#navbarSupportedContent {
  top: 56px;
}

}
.licls .fa.fa-angle-down {
  display: none;
}