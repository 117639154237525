img.paymentLogo {
    width: 200px;
    object-fit: contain;
}
 .bg-gray {
    background: #f3f3f3;
}
i.fa.fa-info-circle.textlight.mr-1 {
    color: #8a8a8a;
}
.rounded {
    border-radius: 10px !important;
}
p.d-block.userHours.active {
    color: #0063dc;
    width: fit-content;
    font-size: 12px;
    font-weight: 600;
    background: #a5b4ff3d;
}

.card.rounded-3 {
    background: rgb(238, 238, 238);
}
.payment_mod_total {
    font-size: 15px;
    color: gray;
}
.payment_mod_usd{
    font-size: 17px;
}
.del_pay_card {
    position: relative;
    top: 3px;
}