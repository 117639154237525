.maincompany {
    margin-left: auto;
    margin-right: auto;
    background: white;
    // max-width: 848px;
    box-shadow: 0px 0px 8px 0px #9e9e9e96;
}

.img_div {
    // text-align: center;
    width: 170px;
    position: relative;
    display: block;
    margin: auto;
}

img.companyimg {
    border-radius: 60px;
    background: #9e9e9e3d;
}

img.companyimg {
    width: 90px;
    height: 90px;
    border-radius: 78px;
    background: #9e9e9e3d;
    object-fit: cover;
}

p.greenlink {
    color: #3574ac;
    font-size: 13px;
}
label.lablecls {
    font-size: 14px;
    font-weight: 600;
}

h3.mainhedding {
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 1px;
}

.companyimg_edit {
    background: #fff;
    box-shadow: 0px 0px 5px 0px grey;
    width: 24px;
    height: 24px;
    padding: 3px 3px;
    border-radius: 15px;
    position: absolute;
    top: 5rem;
    right: 4rem;
    cursor: pointer;
}

input.PhoneInputInput {
    border: none;
    color: #495058;
}

input.img_choose {
    position: absolute;
    bottom: 1.5rem;
    left: 3rem;
    opacity: 0;
}

.up_mob .react-tel-input{
    width: 100% !important;
    border-radius: 30px !important;
}
.up_mob .react-tel-input .form-control {
    background: none !important;
    border: none !important;
    width: 100% !important;
    top: -7px !important;
}

.up_mob .react-tel-input .flag-dropdown {

     background-color: none !important; 
    padding: 0 !important;
 
}
.up_mob .react-tel-input .selected-flag {
   border: none !important;
     width: 100% !important;
}