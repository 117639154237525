
// .vertual_container {
//     max-width: 1309px;
// }

.border_userDate  {
    border: 1px solid #dbdbdb;
    border-radius: 20px;
  }
  .jobsearch_box {
    background: #a1d3e40f;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
}

  // job search
.bg_blue_port{
    border: none;
}

.applybtn {
  width: 222px;
  height: 44px;
}
h3.heeding {
  font-size: 15px;
}
button.btn.btn-outline {
  border: 1px solid #4ba5d9;
  color: #4ba5d9;
}
ul.liststyle {
  list-style: none;
}
a.linkcl {
  color: #4ba5d9;
}

h6.heddingcl {
  font-size: 19px;
}
.sidediv {
  border-left: 1px solid #80808073;
  padding: 12px 19px;
}

h3.smalhead {
  font-size: 14px;
  font-weight: 500;
}

span.freelancer {
  color: #4aa2dc;
}
.morecls {
  font-size: 14px;
  font-weight: 500;
  color: #4aa2dc;
}

a.morecls {
  font-size: 14px;
  font-weight: 500;
  color: #4aa2dc;
}

button.btn.btn-outline-primary.mb-3.applybtn {
  border: 3px solid;
}

h3.head {
  font-size: 17px;
  padding: 12px 14px;
}

p.text-primary.mt-3.skilscls {
  font-size: 15px;
}

h3.hedingclas {
  font-size: 19px;
  font-weight: 600px;
}
.top_box {
    border: 1px solid #dedede;
    border-radius: 20px;
    padding: 16px 25px;
    height: 137px;
  }
  
  .daycls {
    font-size: 20px;
    font-weight: 600;
  }
  
  .messagcls {
    font-size: 26px;
    font-weight: 600;
    color: #000000d9;
  }
  

  .gray_cls {
    color: #000000b5;
}
p.gay_title {
     // color: gray;
     color: #000;
    font-size: 13px;
  }

  .nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    // color: #00000091;
    // background-color: white !important;
.listcls:hover{
    border-radius: 0px !important;
        color: #000000ad !important;
       background-color: none !important;
}

    background: none;
    color: #4ba5d9;
    box-shadow: none;
    border-bottom: 3px solid #4ba5d9;
    border-radius: 0px;
}

  .nav-link .tabcls_ver :active {
  color: #00000091;
    background-color: white !important;
  }

  a.nav-link.active:hover {
    color: #4ba5d9 !important;
}

  .maindiv_boder {
    border: 1px solid #8080804a;
    border-radius: 20px;
}
.jobsearch_box:hover{
background: #a1d3e42e;
}

  span.hourly_rate {
    font-weight: 500;
}
i.fa.fa-star.Rating.mr-1 {
    color: orange;
    font-size: 13px;
}
  
  .sidebox {
    border-radius: 20px;
  }

.lessthan_cls {
    font-size: 13px;
    font-weight: 600;
}

span.Proposals_cls {
    font-weight: 300;
    // color: #000000a6;
    color: #000;
}
  
  // 

  .var_search{
    font-size: 20px;
  }
