img.virtual_img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
    border: 2px solid #ea5c32;
}
.location{
    font-size: 14px;
   position: relative;
   top: 1px;
}
.alignitems-center{
    align-items: center;
}
p.mt-5.desc_font {
    line-height: 22px;
}
.border_total_sale {
    border: 1px solid #ccc;
    padding: 0px;
}
.total_data h5 {
    font-weight: 600;
}
.border_box.p-3 {
    border: 1px solid #ccc;
    border-radius: 10px;
}
p.desc_virtual {
    line-height: 1.5;
    font-size: 16px;
}
h2.name_virtual {
    font-size: 24px;
    color: #3aa0dc;
}
a.back:hover {
    color: #3aa0dc;
}



.myselect {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .myselect option {
    overflow-wrap: break-word !important;
    white-space: normal !important;
    
  }

.Profilehedding.mt-3 {
    font-size: 22px !important;
    font-weight: 600 !important;
    color: #000;
}

.emailclss {
    font-size: 13px;
    font-weight: 600;
    color: #0a5d79;
    word-break: break-all;
}

.profiledetailscls {
    font-size: 14px;
}

.profileheddingcls {
    font-size: 15px;
    font-weight: 600;
    color: #62a0da;
    max-width: 300px;
}

.View_Case {
    font-weight: 700;
}

.profileresulhead {
    font-size: 17px;
    font-weight: 700;
    color: #245B65;
}
.add-border{
    border: 1px solid #ccc;
    padding: 20px;
}
.doument-img{
    height: 80px;
    width: 80px;
    object-fit: cover;
}
.word-break{
    word-break: break-all;
}
.video-div video{
    height: 230px;
    width: 400px;
}
.video-div iframe{
    height: 230px;
    width: 400px;
}
@media(max-width:500px){
    .video-div video{
        height: 200px;
        width: 250px;
    }
    .video-div iframe{
        height: 200px;
        width: 250px;
    }
}