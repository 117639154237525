// .mainfaq{
//     background-image: url("../../../public/assets/img/login_bg.jpg") !important;
// }
.col-md-12.table-responsive {
  box-shadow: 0px 8px 6px #cccccc70;
}
.searchWrapper {
  border: 1px solid #ccc !important;
  border-radius: 20px !important;
  padding: 8px 10px !important;
  position: relative;
}
.faq-section {
    // background: #5fbce587;
    // min-height: 100%;
    // padding: 10vh 0 0;
}
span.bg_bages {
  background: #acd6ec33;
  padding: 5px 10px;
  margin-right: 3px;
  color: #31a3d9;
  font-size: 12px;
  border-radius: 55px;
}
.alingn-items-center{
  border-radius: 14px;
}
.leftalign{
  position: absolute;
  right: 10px;
  top: 10px;
}
  .right{
    right: 15px;
    z-index: 999;
  }
.faq-title h2 {
  position: relative;
  margin-bottom: 45px;
  display: inline-block;
  font-weight: 600;
  line-height: 1;
}
.faq-title h2::before {
    content: "";
    position: absolute;
    left: 50%;
    width: 60px;
    height: 2px;
    background: #00bcd4;
    bottom: -25px;
    margin-left: -30px;
} 
img.project_img {
  width: 100px;
  height: 100px;
  margin-right: 12px;
  border-radius: 15px;
}
a.dropdown-toggle.removeafter:after {
display: none !important;
}
.skill_mar div input {
  margin-top: 0px;
  padding: 2px;
}
.card.shadow.mb-4 {
  box-shadow: 0 .1rem 0.1rem rgba(0,0,0,.15)!important;
}
p.blur_ago {
  // color: #909090;
  color: #000 !important;
}
img.reveleant_user {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 50px;
}
span.gray_badges {
  background: #ededed;
  padding: 8px 19px;
  border-radius: 50px;
  margin-right: 10px;
  margin-top: 10px;
  margin-bottom: 20px;
}
span.material-icons.shield_icon {
  color: #31a3d9;
  font-size: 22px;
  position: relative;
  top: 5px;
}
a.fav_icon_revealeant span {
  border: 1px solid #3aa0dc;
  padding: 10px;
  border-radius: 50px;
  color: #3aa0dc;
  background: #fff;
}

// new design
.search_radius{
  border-radius: 8px;
}
.max_width{
  width: 120px !important;
  border-radius: 8px;
}
.radius_card{
  border-radius: 10px;
}
a.edit_job_post span {
  font-size: 14px;
}

a.edit_job_post {
  border: 1px solid #ccc;
  padding: 7px 7px;
  border-radius: 50px;
  color: #31a3d9;
  display: flex;
  align-items: center;
}
.attachment {
  font-size: 19px;
  line-height: 0;
}
.rate_from {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  width: fit-content;
}
.rate_from input {
  width: 134px;
  text-align: right;
}
.rate_box {
  background: #31a3d961; 
  width: fit-content;
  border-radius: 10px;
  border: 2px solid #31a3d9;
}
.badges_skill {

  height: 200px;
  overflow: auto;
  padding-bottom: 20px;
}
.badges_skill span {
  background: #31a3d93b;
  padding: 6px 12px;
  border-radius: 50px;
  color: #31a3d9;
  margin-right: 10px;
  margin-bottom: 5px;
  margin-top: 5px;
  display: inline-block;
  font-size: 12px;
  cursor: pointer;
}
.modal-header {
  border-bottom: 0px;
}
.modal-content {
  border-radius: 15px;
  // max-height: 680px;
}
.footer_btn {
  background: #fff;
  position: fixed;
  bottom: 0px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 14px 4rem;
  border-top: 1px solid #ccc;
}

// .loremIpsumIs{
//   max-height: 228px;
//   overflow: auto;
// }
.loremIpsumIs p{
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.read_m:hover {
  background-color: #00a5d9 !important;
  border-color: #00a5d9 !important;
  color: #fff !important;
}
.loadgif{
  width: 30px;
  height: 25px;
}
.aibtn{
  width: 158px;
  border-radius: 6px;
  font-size: 16px;
  height: 40px;
}
@media(max-width:480px){
  .lestheader{
    font-size: 18px;
  }
}

.img_skills{
  width: 157px !important;
  height: 161px !important;
  object-fit: cover;
  margin-bottom: 18px;
}

.skills_modal .modal-header {
  position: relative;
}

.skills_modal .modal-header .close {
  position: absolute;
  right: 28px;
  padding: 0;
  top: 28px;
}

.margin_gap{
  margin-bottom: 43px !important;
}     

.skills_modal .modal-header .close:hover {
  width: 26px;
  border-radius: 29px;
 height: 26px;
  color: #000;
  object-fit: cover;
  background-color: #8080807a;
  cursor: pointer;
}
.skills_modal .modal-header .close{
  width: 26px;
  border-radius: 29px;
 height: 26px;
  color: #000;
  object-fit: cover;
}