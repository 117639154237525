input#fname {
    border: none;
    background: #F6F6F6;
}

.material-symbols-outlined{
    font-size: inherit;
}

.mainarea {
    background: #fff;
    padding: 27px;
}

.btn-outline-primary:hover {
    color: #fff;
    background-color: #31a3d9 !important;
    border-color: #31a3d9;
}

textarea#textarea.txarescls {
    border: none;
    background: #F6F6F6;
    height: 300px;
}

.pluseadd {
    color: #197d9f;
    font-size: 13px;
}

.textcls {
    font-size: 13px;
}

.outlinebtn {
    border-radius: 21px !important;
    border: 1px solid #197d9f !important;
    background: #fff;
    color: #197d9f;
    padding: 9px 28px;
    font-weight: 700;
}

.status.status-blue {
    background-color: #D7F5FF;
    color: #197D9F;
}

.status {
    display: inline-block;
    padding: 4px 11px;
    border-radius: 4px;
    font-weight: 400;
    background-color: #e4e4e4;
}

.Profilehedding.mt-3 {
    font-size: 18px;
    font-weight: 700;
    color: #000;
}

.emailclss {
    font-size: 13px;
    font-weight: 600;
    color: #0a5d79;
    word-break: break-all;
}

.profiledetailscls {
    font-size: 14px;
}

.profileheddingcls {
    font-size: 15px;
    font-weight: 600;
    color: #775DA6;
    max-width: 300px;
}

.View_Case {
    font-weight: 700;
}

.profileresulhead {
    font-size: 17px;
    font-weight: 700;
    color: #245B65;
}


.span_high_class.high_risk {
    color: #BA0036 !important;
    background: #F9E4E9 !important;
}

.high_risk {
    display: inline-block;
    padding: 4px 11px !important;
    border-radius: 4px !important;
    font-weight: 600 !important;
}

.span_low_risk.span_low_risk {
    background: #D8FFFE;
    color: #22A59E;
}

.high_risk.span_Moderate_risk {
    color: #8D751A;
    background: #FFF7D7;
}





a.text-primary.view_Case {
    font-weight: 700;
}

.cancelbtn {
    border: 1px solid #197D9F;
    color: #197D9F;
    border-radius: 100px;
    padding: 13px 6rem;
    background: #fff;
}

.sidefont {
    font-size: 14px;
    font-weight: 500;
}

.rightfont {
    font-size: 14px;
}

.savebtn {
    background: #ACACAC;
    color: #fff;
    border-radius: 100px;
    border: none;
    padding: 1rem 6rem;

}

.buttonclass {
    border-radius: 30px;
    width: 150px;
}

.buttonSave {
    border-radius: 30px;
    width: 150px;
    background: gray;
}

.addbtn {
    border-radius: 21px;
}